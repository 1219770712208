<template >
  <v-container class="limited-width pa-0">
<!--
<v-container fluid class="main-banner pa-0">
<v-container>
  <v-row  align="center"  >
  <v-col align="left" cols="12" md="6" sm="6" order="2" order-sm="1"  >
   <h3>{{ $t('dashbaord-banner-heading') }}</h3>
   <p>Lorem Ipsum drem yunp mora heltik marof cigi cigi tagahal.</p>
   <v-btn 
   prepend-icon="mdi-auto-fix" variant="flat" rounded="xl" size="x-large" color="rgb(var(--v-theme-primary))" class="text-white">
  Generate now
  </v-btn>
  </v-col>
  <v-col align="center" cols="12" md="6" sm="6" order="1" order-sm="2">
  <v-img
  aspect-ratio="1/1"
   src= "../assets/mug1.png" 
   style="max-width: 450px;"
   />
  </v-col>
</v-row>
</v-container>
</v-container>
-->
<home-pge-banner></home-pge-banner>
<productSlider productTag="For couples" :noColor="true"></productSlider>
<productSlider productTag="For the little ones"></productSlider>
<productSlider productTag="For her"></productSlider>
<productSlider productTag="For him"></productSlider>


<PromptType></PromptType>

<v-container class="pa-13">
  <v-row>
    <v-col align="center" cols="12" md="4" sm="6" >
      <v-row>
        <v-col cols="4" >
          <v-img
          aspect-ratio="1/1"
          src= "../assets/delivery.svg" 
          style="max-width: 60px;"/>
        </v-col>
        <v-col cols="8"  align="left" >
        <!-- <h3>{{ $t('hello') }}</h3>-->
         <h4>Word-wide Shipping</h4>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0" >
        <v-col cols="12" class="pa-0 ma-0" align="left"><p>We offer world wide shipping using a network of 60+ logistics partners.</p></v-col>
      </v-row>
    </v-col>
    <v-col align="center" cols="12" md="4" sm="6"  >
      <v-row>
        <v-col cols="4" >
          <v-img
          aspect-ratio="1/1"
          src= "../assets/cashback.svg" 
          style="max-width: 60px;"/>
        </v-col>
        <v-col cols="8"  align="left" >
         <h4>Money back guarantee</h4>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="pa-0 ma-0" align="left"><p>Worry free 1 year money back guarantee.</p></v-col>
      </v-row>
    </v-col>
    <v-col align="center" cols="12" md="4" sm="6"  >
      <v-row>
        <v-col cols="4" >
          <v-img
          aspect-ratio="1/1"
          src= "../assets/warranty.svg" 
          style="max-width: 60px;"/>
        </v-col>
        <v-col cols="8" align="left" >
         <h4>100% Secure payment</h4>
        </v-col>
      </v-row>
      <v-row class="pa-0 ma-0">
        <v-col cols="12" class="pa-0 ma-0" align="left"><p>Your payments are 100% securly processed by Stripe. </p></v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
        <CustomerReview productID='for her' :staffPick="true"></CustomerReview>
  </v-row>
     <!-- <GenerationStyles></GenerationStyles>-->
</v-container>

</v-container>
</template>

<script>
 import homePgeBanner from '../components/homePgeBanner.vue';
 import productSlider from '@/components/ProductSlider.vue';
 //import GenerationStyles from '../components/GenerationStyles.vue'
 import PromptType from '../components/PromptType.vue'
 import CustomerReview from '@/components/CustomerReview.vue';

export default {
  name: 'HomeView',
  components: {
    homePgeBanner,
    productSlider,
   // GenerationStyles,
    PromptType,
    CustomerReview
   },
  setup(){
  },
  data(){

  },


}
</script>

<style scoped>
.main-banner{
  background-color: rgb(var(--v-bgAccentColor));
}
.step-col{
  max-width: 279px!important;
}
</style>
