<template>
    <v-dialog v-model="localDialog" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          We only need a face.
        </v-card-title>
        <div class="text-subtitle-2 pl-4 pr-4">Please ensure the face is fully visible within the circle below . You can move and crop the image using 2 fingers. <b>( NO 😎 NO 🤠)</b> </div>
        <v-card-text>
            <div class="cropper-container">
            <img ref="image" :src="imageSrc" />
            <div class="cropper-overlay"></div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="cancel" variant="outlined" color="info" >Cancel</v-btn>
          <v-btn color="primary" @click="cropImage" prepend-icon="mdi-check" variant="flat">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import Cropper from 'cropperjs';
  import 'cropperjs/dist/cropper.css';
  import { trackEvent } from '@/services/amplitudeService';
  
  export default {
    props: {
      modelValue: {
        type: Boolean,
        default: false,
      },
      imageSrc: {
        type: String,
        default: '',
      },
      index: {
        type: Number,
        default: null,
      }
    },
    data() {
      return {
        localDialog: this.modelValue,
        cropper: null,
      };
    },
    watch: {
      modelValue(val) {
        this.localDialog = val;
      },
      localDialog(val) {
        this.$emit('update:modelValue', val);
        if (val) {
          this.$nextTick(() => {
            this.initializeCropper();
          });
        } else {
          this.destroyCropper();
        }
      }
    },
    methods: {
      initializeCropper() {
        const imageElement = this.$refs.image;
      if (imageElement && !this.cropper) {
        this.cropper = new Cropper(imageElement, {
          aspectRatio: 1,
          viewMode: 3,
          dragMode: 'move',
          autoCropArea: 1.0,
          guides: false,
          movable: true,
          scalable: true,
          cropBoxResizable: false,
          cropBoxMovable: false,
          zoomable: true,
          background: false,
          ready: () => {
            const containerData = this.cropper.getContainerData();
            const cropBoxData = this.cropper.getCropBoxData();
            //console.log(containerData.width);
            //console.log(containerData.height);
            this.cropper.setCropBoxData({
             left: (containerData.width - cropBoxData.width) / 2,
             top: (containerData.height - cropBoxData.height) / 2,
              width: containerData.width,
              height: containerData.height
            });
            
          }
        });

        // Ensure the crop box is fixed and the same size as the canvas
        this.cropper.setCropBoxData({
        width: this.cropper.getContainerData().width,
        height: this.cropper.getContainerData().height,
         left: 0,
         top: 0
       });

        // Disable cropping area resizing and moving
        this.cropper.disable();
        this.cropper.clear();
      }
      },
      destroyCropper() {
        if (this.cropper) {
          this.cropper.destroy();
          this.cropper = null;
        }
      },
      cropImage() {
        if (this.cropper) {
            const croppedImage = this.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.$emit('crop', croppedImage, this.index); 
            trackEvent('Image Cropped');
            this.close();
        }
      },
      close() {
        this.localDialog = false;
      },
      cancel(){
        this.$emit('crop', null);
        trackEvent('Image Cropped closed');
        this.close();
      }
    }
  };
  </script>
  
  <style scoped>
  .cropper-container {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
  }
  .cropper-container img {
    max-width: 100%;
    max-height: auto;
 
  }
  .cropper-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/faceguid.svg') center center no-repeat;
    pointer-events: none;
    background-size: cover !important;
    opacity:0.6;
  }
  </style>
  