<template>
    <v-container fluid style="background-image: url('/assets/new-bg.webp'); background-size: cover; background-position: center; background-color: #0061ef;">
      <v-snackbar v-model="showSnackbar" :timeout="5000" color="warning" top>
    {{snackbarMessage }}
    </v-snackbar>
      <v-row>
        <v-col cols="12" >
        <v-sheet
        height="92vh"
        class=" d-flex justify-center align-center"
        style="background: none"
        >
        <v-row class="justify-center align-center border-md rounded-lg  elevation-2 ml-2 mr-2"
          style="max-width:400px; background-color: white;"
        >
        <v-col cols="12" class=" d-flex justify-center align-center">
          <v-img 
           style="max-width:200px;"
          src="/assets/login-logo.webp" 
          >
          </v-img>
        </v-col>
          <v-col cols="12" class="justify-center align-center ma-0 pa-0">
            <div class="text-h6 m"> Welcome back to loonify!</div>

          </v-col>

          <v-col cols="12" class="pr-10 pl-10 mb-2" v-if="!codeSent">
            <div class="text-subtitle-2 mb-4"> Please enter your Email to proceed</div>
            <v-text-field   type="email" autocomplete="email" v-model="email" label="E-mail" variant="outlined" color="primary" :rules="emailRules"  ref="emailInput"></v-text-field>
            <v-btn type="submit" color="primary" size="large" variant="outlined" @click="validateEmail">NEXT</v-btn>
          </v-col>
        <v-col cols="12" v-if="codeSent" class="mb-10">
          <div class="text-subtitle-2 mb-4" >We have sent a <b>5 digit</b> code to your email. Please enter the code here: </div>
          <v-row justify="center" >
            <v-col cols="2" v-for="(value, index) in code" :key="index" class="pa-2">
              <v-text-field
                v-model="code[index]"
                ref="digitFields"
                class="text-center"
                inputmode="numeric"
                solo
                hide-details
                variant="outlined" color="primary"
                @input="onInput(index, $event)"
                @paste="onPaste($event)"
                @keydown="onKeydown(index, $event)"
              />
            </v-col>
            <v-btn @click="submitCode"  color="primary" size="large" class="mt-4">Login</v-btn>
            
        </v-row>
        </v-col>
       <v-col cols="12">
        <v-row class="justify-center align-center ma-0 pa-0">
          <div class="text-subtitle-2">Dont have an account yet?</div>
        </v-row>
        <v-row class="justify-center align-center ma-0 pa-0">
          <v-btn variant="text" color="primary" size="small">Sign up</v-btn>
          <div class="text-subtitle-2">or</div>
          <v-btn variant="text" color="primary" size="small">Countinue as a guest</v-btn>
        </v-row>
       </v-col>
        </v-row>

      


      </v-sheet>
      
        </v-col>
        


      </v-row>

    </v-container>

</template>

<script>
import { mapActions } from 'vuex';

import axios from 'axios';

export default {
  data() {
    return {
       // Make sure these are simple reactive properties
      email: "",
      code: ["", "", "", "", ""], // Array to hold the 5 digits
      codeSent: false,
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      showSnackbar: false,      // Controls the visibility of the snackbar
      snackbarMessage: '',      // Message to show in the snackbar
      validCode: false,
      strCode: null,
    };
  },
  methods: {
    ...mapActions(['login']),
    async handleLogin() {
      try {
        await this.login({ username: this.email, password: this.strCode });
        this.$router.push({ name: 'home'});
      } catch (error) {
       if(error.response.status === 401){
        this.snackbarMessage = 'You have entered a wrong code!';
        this.showSnackbar = true;
       }
      }
    },
    onInput(index, event) {
  const value = event.target.value;
  // Check if more than one digit is entered (keyboard suggestion or auto-fill)
  if (value.length > 1) {
    // Check if it's exactly 5 digits
    if (/^\d{5}$/.test(value)) {
      this.code = value.split("");

      // Automatically focus on the last field
      this.$refs.digitFields[4].focus();
    } else {
      // If more than one character but invalid, clear the input
      this.code[index] = "";
    }
  } else if (/^\d$/.test(value)) {
    // Single digit case (normal typing)
    this.code[index] = value;

    // Move to the next field if available
    if (index < 4) {
      this.$refs.digitFields[index + 1].focus();
    }
  } else {
    // Clear the field if input is invalid
    this.code[index] = "";
  }
},
    onPaste(event) {
      const pastedData = (event.clipboardData || window.clipboardData).getData("text");

      // Check if pasted content is 5 digits
      if (/^\d{5}$/.test(pastedData)) {
        this.code = pastedData.split("");

        // Automatically focus on the last field
        this.$refs.digitFields[4].focus();
      }

      // Prevent the default paste behavior
      event.preventDefault();
    },
    onKeydown(index, event) {
    if (event.key === "Backspace" || event.key === "Delete") {
      // Clear the current field
      this.code[index] = "";

      // If not at the first field, move to the previous field
      if (index > 0) {
        this.$refs.digitFields[index - 1].focus();
      }
    }
  },
    async validateEmail() {
      this.emailError = false;
      this.emailErrorMessage = '';
      if(!this.email){
        this.snackbarMessage = 'Please enter your email address';
        this.showSnackbar = true;
      }

      // Check each rule in the emailRules array
      for (let rule of this.emailRules) {
        const result = rule(this.email);
        if (result !== true) {
          this.emailError = true;
          this.emailErrorMessage = result; // Display the error message
          break; // Stop at the first failed rule
        }
      }

      if (!this.emailError) {
        // All rules passed, proceed further
        try {
      const response = await axios.post('/apis/loginCode', { username: this.email });
      
      if (response.status === 200) {
        // Do something on success (status 200)
        this.codeSent = true;
      } 
    } catch (error) {
      if (error.response && error.response.status === 401) {
        this.snackbarMessage = 'Email address does not exist';
        this.showSnackbar = true;
      } else if (error.response && error.response.status === 500) {
        console.log('Server error. Please try again later.');
      } else {
        console.log('An unexpected error occurred:', error.message);
      }
    }

      } 
    },
    async submitCode(){
      const allValid = this.code.every(item => item !== null && /^[0-9]+$/.test(item));
      if (allValid) {
    // All items are valid (not null and contain only digits)
    this.strCode =  this.code.join('');

      this.handleLogin();

  } else {
    this.snackbarMessage = 'Please enter your 5 digit code';
    this.showSnackbar = true;
  }
    }
  
  }

};
</script>
<style scoped>

</style>
