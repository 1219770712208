<template>
<v-container style="background-color: #f7f7f7;" fluid class="height-screen">
    <v-row >
      <v-col>
        <div ref="canvascontainer">
          <CanvasComponent ref="MyCanvas" :userProductID="userProductID" :canvasContainerWidth="canvasContainerWidth"/>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
 
 <script>
 //import axios from 'axios';
 import CanvasComponent from '../components/CanvasComponent.vue';
//import { cos } from 'fabric/dist/src/util';
 
 export default{
     name: 'EditDesign',
     components: {
     CanvasComponent,
   },
   data(){
    return {
      userProductID: null,
      canvasContainerWidth: 0,
    };
   },
    created(){
    const { UserProductID } = this.$route.params;
    this.userProductID = UserProductID;
    


   },
    mounted() {
    //this.getUserProduct(this.userProductID);

     window.addEventListener("resize", this.Resizehandler);
     //calling this due to issue on size on mobile? fo esoem reason before resizing the size was wierd
     this.canvasContainerWidth = this.$refs.canvascontainer.clientWidth;
     this.$refs.MyCanvas.ResizeCanvas(this.$refs.canvascontainer.clientWidth);
     this.$refs.MyCanvas.ResizeCanvas(this.$refs.canvascontainer.clientWidth+1);
     this.$refs.MyCanvas.ResizeCanvas(this.$refs.canvascontainer.clientWidth-1);

 },
 unmounted() {
     // TODO check if event listener is removed when exiting the page TODO see if happend again.
      window.removeEventListener("resize", this.Resizehandler);
 },
 
 methods: {
    // this is to handle canvas size change to be responsive 
     Resizehandler() {
    this.$refs.MyCanvas.ResizeCanvas(this.$refs.canvascontainer.clientWidth);
     },

 }
 }
 </script>
 
 <style scoped>

 
 .height-screen {
   height: 100vh; /* 100% of the viewport height */
 }
 </style>