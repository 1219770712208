<template>
<v-container v-if="issmAndUp">
  <v-row>
    <v-carousel show-arrows="hover"  class="h-100 ma-0 pa-0" hide-delimiters >
        <v-carousel-item
        v-for="(banner, index) in banners"
           :key="index"
           v-show="true"
          >
          <v-row>
          <v-col cols="12" sm="6">
            <v-sheet 
            class="d-flex justify-center align-center rounded-xl gradient-card heart-bg  "
            style="background-color: #ff7300;" 
            >
            <v-carousel :show-arrows="false" cycle interval="2000"  class=" w-100 ma-0 pa-0" hide-delimiters >
                <v-carousel-item
                v-for="(image, index) in banner.imageUrls"
                :key="index"
                v-show="true"
                transition="slide-x-transition"
                reverse-transition="slide-x-reverse-transition"
                >
                    <v-img fit
                    aspect-ratio="1"
                     :src="image"
                    ></v-img>
                </v-carousel-item>
            </v-carousel>

            </v-sheet>
            </v-col>
            <v-col cols="12" sm="6" class=" d-flex justify-center align-center ">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="5">
                   
                            <v-img
                            src="/assets/arrow.svg"
                            max-width="200"
                            >
                            </v-img>
                            
                        </v-col>
                        <v-col cols="4">
                            <v-img
                            src="/assets/yourpic_1.webp"
                            max-width="200"
                            ></v-img>

                        </v-col>

                    </v-row>

                </v-col>
                <v-col cols="12">
                <div class="text-h3 ma-1 ma-1" align="left" v-html="banner.title "></div>
                </v-col>
                <v-col cols="12">
                <div class="text-subtitle-2 pa-1 ma-1" align="left">
                    {{banner.subtitle}}
            </div>
                </v-col>
                <v-col align="left">
                <v-btn color="primary" size="x-large" class="rounded-xl"  @click="this.goToPage(banner.ctaName, banner.ctaParams)"  prepend-icon="mdi-heart-multiple">{{banner.cta}}</v-btn>
                </v-col>
            </v-row>
            <v-row>
            </v-row>
            </v-col>
        </v-row>

        </v-carousel-item>
    </v-carousel>

  </v-row>
</v-container>
<v-container fluid v-if="!issmAndUp" >
    <v-row>
     <v-carousel :show-arrows="false"  class="h-100 ma-0 mt-3 ml-1 mr-1" hide-delimiters >
        <v-carousel-item 
        v-for="(banner, index) in banners"
           :key="index"
           v-show="true"
          >
         <v-row>
        
        <v-col cols="12"    >
            <v-sheet 
            class=" d-flex justify-center align-center rounded-xl pt-8 pb-5 gradient-card heart-bg"
              width="100%"
              style="background-color: #ff7300;" 
            >
            <v-row >

            <v-col cols="7">
            <v-carousel :show-arrows="false" cycle interval="2000"  class="h-100 w-100 " hide-delimiters 
            >
                <v-carousel-item
                v-for="(image, index) in banner.imageUrls"
                :key="index"
                v-show="true"
                :show-arrows="false"
                >
                    <v-img fit
                    aspect-ratio="1"
                    :src="image"
                    class="d-flex ma-0"
                    ></v-img>
                </v-carousel-item>
            </v-carousel>
        </v-col>
        <v-col cols="5" align="left" class="d-flex  align-center ">
            <v-row>
               <v-col cols="12">
                   <v-img
                   src="/assets/yourpic_1.webp"
                   style="position: relative; left : -20px"
                   ></v-img>

               </v-col>

            </v-row>
        </v-col>
        <v-col cols="12">
                    <div class="text-h4 ml-5 mr-5 " style=" color: #fff;"  align="left" v-html="banner.title"></div>
                </v-col>
                <v-col cols="12" class="" align="left">
                    <v-btn color="primary" size="large" class="rounded-xl  ml-5 mr-5" style=" " @click="this.goToPage(banner.ctaName, banner.ctaParams)" prepend-icon="mdi-heart-multiple">{{banner.cta}}</v-btn>
                </v-col>
        <v-col cols="12"> 
            <div class="text-caption ml-5 mr-5 " style="color: #fff;" align="left">
                    {{banner.subtitle}}
            </div>
        </v-col>
        </v-row>
    </v-sheet>
        </v-col>

    </v-row>
    </v-carousel-item>
    </v-carousel>
    </v-row>



</v-container>
</template>
<script>
import { useDisplay } from 'vuetify';
export default {
  name: 'HomePageBanner',
  setup(){
  },
  data(){
    return{
    metaInfo() {
    return {
      title: "Gifts with a WOW factor.",
      meta: [
        { name: 'og:title', content: this.banners[0].title },
        { name: 'og:description', content: this.bannersp[0].subtitle},
        { name: 'og:url', content: 'https://loonify.ai'},
        { name: 'og:image', content: this.bannersp[0].imageUrlsp[0]},

      ],
      
    };
  },
    issmAndUp: false,
    banners:[
        {
            imageUrls: ["/assets/edisonmug.png","/assets/edisonmug.png"],
            title: '❤️ Super especial Valentine Gifts.',
            subtitle: 'Generate beautiful gifts using only one photo. Upload a photo, describe your charachter and BOOM! your personalissed gift is ready to be shipped! ',
            cta: 'Explore now',
            ctaName: 'GenerateDesign',
            ctaParams:{productID:'66a4dcf32d95d624301248a3'}

        }
    ]
    }
    
  },
  created(){
    const { smAndUp } = useDisplay();
    this.issmAndUp =smAndUp;
  },
  methods: {
    goToPage(name, params) {
        
        this.$router.push({ name: name , params: params});
    }
  }

}
</script>
<style scoped>
    .gradient-card {
      background: radial-gradient(circle, rgba(255,255,255,0.5) 0%, rgba(225,115,0,1) 100%);
    }
    .heart-bg {
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path opacity="0.2" fill="%23ff0000" d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 6.42 3.42 5 5.5 5c1.54 0 3.04.99 3.57 2.36h.87C12.46 5.99 13.96 5 15.5 5 17.58 5 19 6.42 19 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z"/></svg>');
  background-repeat: repeat; /* Replace with your actual path to the SVG */
  background-repeat: repeat; /* Ensures the pattern repeats */
  background-size: 30px 30px; /* Adjust the size of the pattern */
 
}
</style>