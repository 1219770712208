<template>
<v-container>
    <v-row>
        <v-col class="w-100" align="center">
            <v-img
            max-width="600"
            :src="this.getImageUrl('/productImages/404.jpg')"
          
            >

            </v-img>
        </v-col>
        <v-col cols="12">
           <v-btn
           color="primary"
           size="x-large"
           @click="this.goback()"
           prepend-icon="mdi-home"
           >
           Go Home
           </v-btn>
        </v-col>
    </v-row>
</v-container>
</template>
<script>
export default{
    name: 'NotFound404',
    methods:{
        getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
        },
        goback(){
            
            this.$router.replace({name:'home'})
        }
    }
}
</script>
<style scoped>
</style>