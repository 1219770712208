    <template >
    <v-sheet
    class="mx-auto"
    elevation="0"
    max-width="1192"
    >
    <v-container>
    <v-row class="mt-0 ">
      <v-col cols="7" align="left" class="pb-0 mb-0 pl-0">
        <div class="text-h6 text-uppercase  ml-5 mr-5" align="left">{{ this.productStyle || this.productTag }}</div>
      </v-col>
      <v-col cols="5" align="right" class="pb-0 mb-0">
        <v-btn variant="text" @click="viewAll()" color="primary" append-icon="mdi-chevron-right">View all</v-btn>
      </v-col>
        
    </v-row>
    </v-container>
    <v-container class="pt-0 m-t-0">
    <v-slide-group
      v-model="model"
      class="ml-0 mr-0"
      show-arrows="false"
    >
    <v-slide-group-item>
        <v-card
        v-for="(product, index) in this.products"
        :key="product._id"
          class="ma-2 rounded-xl"
          height="370"
          width="250"
          @click="viewProduct(product._id)"
          elevation="0"
          :color="noColor ? colors[index] : '#f9eeee'"
        >
        <v-img fit
            aspect-ratio="1"
            :src="getImageUrl(product.Thumbnails[0])"
            >
            <v-row  class="d-flex justify-end align-start" style="position: absolute; top: 0; right: 0;">
            <v-btn
              :icon="product.liked ? 'mdi-heart' : 'mdi-heart-outline'"
              size="small"
              class="ma-5"
              variant="tonal"
              @click.stop="toggleLike(product.id, index)"
              :color="product.liked ? 'red' : 'gray'"
            />
            </v-row>
            
            </v-img>
            <v-row class="">
            <v-col cols="7" class="mb-0 pb-0 mt-0 pt-2">
            <div class="text-subtitle-2 ma-0 pl-3 mb-0 pb-0 mt-0 pt-0" align="left">{{product.Category}}</div>
           
           </v-col>
           <v-col cols="5" class="ma-0 pa-0">
           <v-chip >{{product.unitPrice}} €</v-chip>
           </v-col>
           <v-col cols="12" class="mt-0 pt-0">
            <div class="text-h7 ma-0 pl-3 pt-0" align="left" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{product.Name.en}}</div>
           </v-col>
           <v-col>
                    <v-btn variant="text" append-icon="mdi-chevron-right"  class="w-100 pa-0 ma-0 pb-0" elevation="0">Personalise</v-btn>
            </v-col>
            </v-row>
        </v-card>
    </v-slide-group-item>
    <v-slide-group-item>
    
    </v-slide-group-item>
    </v-slide-group>
  </v-container>
    </v-sheet>

    </template>
    <script>
    import { useDisplay } from 'vuetify';
    import axios from 'axios';
    export default {
      name: 'HomePageBanner',
      props: {
        productStyle: {
        type: String,
        required: false,
        default: null
        },
        productTag:{
            type: String,
            required: false,
            default: null
        },
        noColor:{
          type: Boolean,
          required: false,
          default: false,

        }
        },
      setup(){
      },
      data(){
        return{
        backendURL:null,
        issmAndUp: false,
        products:[],
        colors:["#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6","#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6","#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6","#3b1a7f","#0061ef","#ffe14f","#ff7300","#00a4ff","#9e66c6",]
        }
      },
      created(){
        const { smAndUp } = useDisplay();
        this.issmAndUp =smAndUp;
        this.fetchProducts(this.productTag,this.productStyle);
      },
      mounted(){
       
      },
      methods:{
        toggleLike(productId, index) {
        // Toggle the liked state of the product
        this.products[index].liked = !this.products[index].liked;

        // Call the API with the product ID and liked status
       // this.saveLikeState(productId, this.products[index].liked);
        },
        async fetchProducts(productTag, productStyle) {
            try {
                // Call your API using Axios
                const response = await axios.post('/apis/getProductsCategory', {
                productTag: productTag, // Data sent in the body
                productStyle: productStyle,
                });

                // Set the products from the API response
                this.products = response.data.products;
            } catch (err) {
                // Handle errors and set the error message
                this.error = 'Error fetching products: ' + err.response.data;
            }
            },
            getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
            },
            viewAll(){
            console.log("meherere");
            if(this.productTag){
              
              this.$router.push({ name: 'ProductsPage', params: { ProductTag: this.productTag} });
            }
            if(this.productStyle){
              this.$router.push({ name: 'ProductsPage', params: { ProductTag: this.productStyle} });
            }
          },
          viewProduct(productId){
            this.$router.push({ name: 'GenerateDesign', params: { productID: productId} });
          }

      },

    
    }
    </script>
    <style >
    .v-slide-group__prev {
        display: none;
    }
    .v-slide-group__next {
        display: none;
    }
    </style>