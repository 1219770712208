import { createApp } from 'vue'
import { createMetaManager } from 'vue-meta';
import App from './App.vue'
import router from './router'

// Import Vuetify and styles
import { createVuetify } from 'vuetify'
import 'vuetify/styles'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css' 
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { createI18n } from 'vue-i18n'
import en from './assets/local/en.json'
import de from './assets/local/de.json'
import store from './store/store'; 
import { initializeAmplitude } from './services/amplitudeService';


const messages = {
  en,
  de
}



const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages,
  
})


// Create Vuetify instance
const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme: {
        dark: false,
        colors: {
          primary: '#0061ef', 
          secondary: '#45423f', // Example secondary color
          accent: '#ffce01', // Example accent color
          info: '#2196F3', // Example info color
          success: '#ffa502', // Example success color
          warning: '#ff7300' // Example warning color
        },
        variables: {
            primaryColor: '#0061ef', 
            bgAccentColor: '#faf6f3',
            secondaryColor: '#45423f', // Example secondary color
            accentColor: '#ffce01', // Example accent color
            infoColor: '#2196F3', // Example info color
            successColor: '#ffa502', // Example success color
            warningColor: '#ff7300' // Example warning color
        }
      },
      options: {
        customProperties: true,
      },
      defaultAssets: {
        font: {
          family: 'Ubuntu',
        },
      },
    }
  },
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
})




router.beforeEach((to, from, next) => {
  const lang = to.params.lang
  if (lang) {
    i18n.global.locale = lang
  }
  next()
})

// Create Vue app and use Vuetify and Router
const app = createApp(App)
initializeAmplitude();
app.use(router)
app.use(createMetaManager());
app.use(vuetify)
app.use(i18n)
app.use(store)
app.mount('#app')

//createApp(App).use(router).mount('#app')
