<template>
    <v-row>
      <!-- Size Selection -->
       <v-col cols="12" class="ma-0 pa-0">
      <v-slide-group
        v-if="sizes.length > 0"
        v-model="selectedSize"
        show-arrows="false"
        class="mb-2"
      >
        <div class="text-subtitle-2 pr-2 d-flex justify-center align-center">Size: </div>
        <v-slide-item
          v-for="size in sizes"
          :key="size.size"
          :value="size.size"
        class="pr-2"
        >
          <v-btn
            :color="size.size === selectedSize ? 'primary' : 'grey'"
            @click="updateAvailableColors(size.size)"
            size="small"
            variant="outlined"
           >
            {{ size.size }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-col>
      <!-- Color Selection -->
      <v-col cols="12" class="ma-0 pa-0">
      <v-slide-group
        v-if="availableColors.length > 0"
        v-model="selectedColor"
     
        class="mb-4"
        show-arrows="false"
      >
      <div class="text-subtitle-2 pr-2 d-flex justify-center align-center">Color: </div>
        <v-slide-item
          v-for="color in availableColors"
          :key="color.color_name"
          :value="color.color_name"
          class="pr-2"
        >
          <v-btn
            :color="color.color_name === selectedColor ? 'primary' : 'grey'"
            @click="emitProductId(color.color_name)"
            size="xsmall"
              variant="outlined"
          >  
          <v-icon icon="mdi-circle" class="border-thin rounded-circle" :color="color.color_code" size="large"></v-icon>
          </v-btn>
        </v-slide-item>
      </v-slide-group>
      </v-col>
    
    </v-row>

   
  </template>
  
  <script>
  export default {
    props: {
      variants: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        selectedSize: null,
        selectedColor: null,
        availableColors: []
      };
    },
    computed: {
      sizes() {
        return this.variants.sizes || [];
      }
    },
    methods: {
      updateAvailableColors(size) {
        // Set selected size and update available colors
        this.selectedSize = size;
        const sizeVariant = this.sizes.find((s) => s.size === size);
        this.availableColors = sizeVariant ? sizeVariant.colors : [];
  
        // Set default color if specified, or first color if no default
        const defaultColor = this.availableColors.find((color) => color.default) || this.availableColors[0];
        this.selectedColor = defaultColor ? defaultColor.color_name : null;
  
        // Emit product ID for the default color selection
        if (this.selectedColor) {
          this.emitProductId(this.selectedColor);
        }
      },
      emitProductId(colorName) {
        // Find the external product ID based on selected size and color
        const selectedVariant = this.availableColors.find(
          (color) => color.color_name === colorName
        );
        if (selectedVariant) {
          this.$emit("variantSelected", selectedVariant.external_product_id);
          this.selectedColor = colorName; // Ensure button style updates correctly
        }
      },

    },
    mounted() {
      // Set the initial size and color based on defaults
      const defaultSize = this.sizes.find((size) =>
        size.colors.some((color) => color.default)
      ) || this.sizes[0];
  
      if (defaultSize) {
        this.selectedSize = defaultSize.size;
        this.updateAvailableColors(this.selectedSize);
      }
    }
  };
  </script>
  
  <style scoped>
  .selected-color {
    border: 2px solid #1976D2; /* Primary color to indicate selection */
  }
  </style>
  