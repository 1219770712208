<template>

    <v-dialog v-model="internalModelValue" persistent max-width="800">
     <v-card >
      <v-card-text >
            <v-row>
                <v-col cols="6" align="start">
                    <div class="text-subtitle-2">Click on Images to add</div>
                </v-col>
                <v-col cols="6" align="end">
                    <v-btn color="primary" icon="mdi-close"  @click="closeDialog" size="x-small" variant="outlined"></v-btn>
                 </v-col>
            </v-row>
            <v-row class="fill-height	">

                <v-col cols="4" sm="4" md="4" align="center" >
                <v-col class="border-md border-dotted  rounded-sm fill-height align-center justify-center d-flex">
                    <v-row class="align-center justify-center pa-0">
                        <v-col cols="12" class="ma-0 pa-0"><div class="text-subtitle-2">Generate more</div></v-col>
                        <v-col cols="12" class="ma-0 pa-0"><v-btn color="primary" icon="mdi-creation"  @click="closeDialog" size="small" variant="tonal"></v-btn></v-col>
                        

                        
                    </v-row> 
                </v-col>
                </v-col>

            <!-- Image thumbnails -->
            <v-col 
                v-for="image in AIimages" 
                :key="image._id" 
                cols="4" sm="4" md="4"
                class=""
            >
            <v-row>
                <v-img 
                width="30"
                :aspect-ratio="1" cover 
                :src="getImageUrl(`/genimages/${image.fileName}`)"
                :ref="image._id"
                :id="image._id"
                class="image-thumbnail rounded border-thin ma-2"
                @click="selectImage(image.fileName)"
                />

            </v-row>

            </v-col>
            </v-row>

      </v-card-text>

    </v-card>
    </v-dialog>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      userEmail: null,
      addGraphicToCanvas: Function,
    },
    async created(){
   const token = this.$store.getters.getToken;
   const result = await axios.get('/apis/AIimages', {
                headers: {
                    'Authorization': token // Pass the token for authorization
                }
            });
   // const result = await axios.get(`/apis/AIimages/${this.userEmail}`);
    const images = result.data;
    this.AIimages = images;
    console.log(images)

    },
    data() {
      return {
        internalModelValue: this.modelValue,
        AIimages: {},
      
      };
    },
    watch: {
      modelValue(newVal) {
        this.internalModelValue = newVal;
      },
      internalModelValue(newVal) {
        this.$emit('update:model-value', newVal);
      },
    },
    methods: {
      closeDialog() {
        this.internalModelValue = false;
      },

    selectImage(imageSrc) {
      const url=this.getImageUrl(`/genimages/${imageSrc}`);
      this.addGraphicToCanvas(url);
    },
    getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
    },
    },

  };
  </script>
  <style scoped>
.empty-space {
  cursor: pointer;
}

.image-thumbnail {
  cursor: pointer;
}
.selected {
  border: 2px dashed rgba(173, 173, 173, 0.692);
}
</style>