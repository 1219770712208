<template>

    <v-dialog v-model="internalModelValue" persistent max-width="800">
     <v-card >
      <v-card-text >
            <v-row>
                <v-col cols="6" align="start">
                    <div class="text-subtitle-2">Click on graphics to add</div>
                </v-col>
                <v-col cols="6" align="end">
                    <v-btn color="primary" icon="mdi-close"  @click="closeDialog" size="x-small" variant="outlined"></v-btn>
                 </v-col>

            </v-row>
        <div v-for="(category, index) in categories" :key="index" style="background-color:#f7f6f5" class="pl-4 pr-4 mt-6 rounded">
            <v-row class="pt-1">
            <v-col cols="6" align="start" class="pa-1">
                <div class="text-subtitle-2">{{ category.name }}</div>
                
            </v-col>
            <v-col cols="6" align="end"  class="pa-1">
                <v-btn variant="text" size="x-small" @click="toggleExpand(category)" color="primary">
            {{ category.expanded ? 'Show Less' : 'Show More' }}
            </v-btn>
            </v-col>
          </v-row>

          <v-row class="" >
            <v-col v-for="(image, imgIndex) in getImagesToShow(category)" :key="imgIndex" cols="auto" class="border-thin ma-1 rounded bg-white">
              <v-img :src="image.src" width="60" class="rounded bg-white "   :aspect-ratio="1" cover 
              @click="selectImage(image.src)"
               style="cursor: pointer;"
              ></v-img>
            </v-col>
          </v-row>

         
        </div>
      </v-card-text>

    </v-card>




    </v-dialog>

  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
      addGraphicToCanvas: Function,
    },
    async created(){

    const result = await axios.get('/apis/canvasGraphics');
    const graphics = result.data;
    this.categories = graphics;

    },
    data() {
      return {
        internalModelValue: this.modelValue,
        categories: {},
      
      };
    },
    watch: {
      modelValue(newVal) {
        this.internalModelValue = newVal;
      },
      internalModelValue(newVal) {
        this.$emit('update:model-value', newVal);
      },
    },
    methods: {
      closeDialog() {
        this.internalModelValue = false;
      },
      getImagesToShow(category) {
      return category.expanded ? category.images : category.images.slice(0, 6);
    },
    toggleExpand(category) {
      category.expanded = !category.expanded;
    },
    selectImage(imageSrc) {
      this.addGraphicToCanvas(imageSrc);
    },
    },

  };
  </script>
  