
<template>
  <v-app-bar app>
    <v-row class="ma-0 pa-0">
      <v-col cols="6" align="start" class="ma-0 pa-0">
        <v-btn variant=""  icon="mdi-arrow-left" class="ml-2" rounded="4" size="large" @click="goBack"></v-btn>
      </v-col>
      <v-col cols="6" align="end">
      <v-btn variant="elevated" color="primary" prepend-icon="mdi-check" rounded="4" @click="UpdateProduct" >
        Finish Design
      </v-btn>
      </v-col>
    </v-row>
  </v-app-bar>

  <v-row style="background-color: white; "  v-if="(!isObjectSelected && issmAndUp) || !issmAndUp">
    <v-col cols="auto" class="ma-0 pa-1" align="start">

      <v-menu>
      <template v-slot:activator="{ props }">
      <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
        <v-icon icon="mdi-circle" class="border-md rounded-circle" :color="BGCOLOR" size="large"></v-icon>
        BG COLOR
      </v-btn>
      </template>
      <v-color-picker hide-inputs show-swatches v-model="BGCOLOR"></v-color-picker>
      </v-menu>
      
      <v-btn prepend-icon="mdi-magnify-plus-outline" class="ma-0 pa-1" rounded="0" stacked size="x-small" variant="text" @click="canvasZoomIn">
        Zoom In
      </v-btn>
      <v-btn prepend-icon="mdi-magnify-minus-outline" class="ma-0 pa-1" rounded="0" stacked size="x-small" variant="text" @click="canvasZoomOut">
        Zoom Out
      </v-btn>
      <v-btn prepend-icon="mdi-eye" class="ma-0 pa-1" rounded="0" stacked size="x-small" variant="text" @click="exportCanvas">
      export
      </v-btn>
 
          <v-btn prepend-icon="mdi-undo" class="ma-0 pa-1" rounded="0" stacked size="x-small"  :disabled="!canUndo" variant="text" @click="undo">
      Undo
        </v-btn>
        </v-col>
      </v-row>
      
      <v-row style="background-color: white; "  v-if="isObjectSelected && issmAndUp">

        <v-col cols="auto" class="ma-0 pa-1" align="start">
          <v-btn prepend-icon="mdi-delete" class="ma-0 pa-1" rounded="0" stacked size="x-small" variant="text"  @click="removeSelectedObject">
          Remove
          </v-btn>
          <v-btn prepend-icon="mdi-content-copy" class="ma-0  pa-1" rounded="0" stacked size="x-small" variant="text"  @click="duplicateSelectedObject">
          Duplicate
          </v-btn>
          <v-menu width="210">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0  pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-layers" size="large"></v-icon>
              Position
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn  prepend-icon="mdi-arrange-bring-to-front" class="ma-0" stacked  size="x-small" variant="text" @click="bringFront">To front</v-btn>  
                <v-btn  prepend-icon="mdi-arrange-send-to-back" class="ma-0" stacked size="x-small" variant="text" @click="sendBack">To back</v-btn> 
                <v-btn  prepend-icon="mdi-arrange-bring-forward" class="ma-0" stacked size="x-small" variant="text" @click="bringForward">Forward</v-btn> 
                <v-btn  prepend-icon="mdi-arrange-send-backward" class="ma-0" stacked size="x-small" variant="text" @click="sendBackward">Backward</v-btn>                   
              </v-list-item>
            </v-list>
          </v-menu>

        </v-col>
        <v-divider
          vertical></v-divider>
    
        <v-col cols="auto" class="ma-0 pa-1 " align="start" v-if="selectedObjectType === 'textbox'">
          <v-menu>
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-circle" class="border-md rounded-pill" :color="TEXTCOLOR" size="large"></v-icon>
              COLOR
              </v-btn>
            </template>
            <v-color-picker hide-inputs show-swatches v-model="TEXTCOLOR"></v-color-picker>
          </v-menu>
          <v-menu width="200">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-line-spacing" size="large"></v-icon>
              Line space
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="3" min="0.1" tickSize="0.0001" class="pa-1" v-model="LINEHIGHT"></v-slider></v-list-item>
            </v-list>
          </v-menu>
          <v-menu width="200">
          <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-letter-spacing" size="large"></v-icon>
              Letter space
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="1000" min="-500" tickSize="1" class="pa-1" v-model="CHARSPACE"></v-slider></v-list-item>
            </v-list>
          </v-menu>
          <v-menu >
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-align-center" size="large"></v-icon>
              ALIGN
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn  icon="mdi-format-align-left" class="ma-0"  size="small" variant="text" @click="TEXTALIGN='left'"></v-btn>  
                <v-btn  icon="mdi-format-align-center" class="ma-0"  size="small" variant="text" @click="TEXTALIGN='center'"></v-btn> 
                <v-btn  icon="mdi-format-align-right" class="ma-0"  size="small" variant="text" @click="TEXTALIGN='right'"></v-btn>                   
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu >
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-font" size="large"></v-icon>
              FONT
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
               <!-- <v-btn  icon="mdi-format-bold" class="ma-0"  size="small" variant="text" @click="applyBold"></v-btn>  -->
               <!-- <v-btn  icon="mdi-format-italic" class="ma-0"  size="small" variant="text" @click="applyItalic" ></v-btn> -->
                <v-btn  icon="mdi-format-underline" class="ma-0"  size="small" variant="text" @click="applyUnderLine"></v-btn>  
                <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn  icon="mdi-format-color-highlight" class="ma-0"  size="small" variant="text"  v-bind="props"></v-btn>  
                </template>
                <v-color-picker hide-inputs show-swatches v-model="HIGHLIGHTCOLOR"></v-color-picker>
                </v-menu>                 
              </v-list-item>
              <v-list-item>
                <v-virtual-scroll
                  :items="fonts"
                  item-height="30"
                  height="300"
                >
                  <template v-slot:default="{ item }">
                    <v-row class="pa-2 cursor-pointer" :style="{ fontFamily: `'${item}'`}"  @click="applyFont(item)">
                      <div class="pl-3	" style="font-size: larger">{{ item }}</div>
                       
                    </v-row>
                  </template>
                </v-virtual-scroll>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu width="200">
          <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-text-variant-outline" size="large"></v-icon>
              Stroke
              </v-btn>
            </template>
            <v-list >
              <v-list-item>
                <v-menu>
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"   size="small" variant="text" v-bind="props" >
              <v-icon icon="mdi-circle" class="border-md rounded-pill" :color="TEXTSTROKECOLOR" size="large"></v-icon>
               COLOR
              </v-btn>
            </template>
            <v-color-picker hide-inputs show-swatches v-model="TEXTSTROKECOLOR"></v-color-picker>
          </v-menu>

              </v-list-item>
              <v-list-item><v-slider  max="40" min="0" step="1" tickSize="1" class="pa-1" v-model="TEXTSTROKE"></v-slider></v-list-item>
              
            </v-list>
          </v-menu>

        </v-col>
        <v-col cols="auto" class="ma-0 pa-1 " align="start" v-if="selectedObjectType === 'group'">
          <v-menu v-for="(color, id) in SVGColorMap" :key="id" >
          <template v-slot:activator="{ props }">
          <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
            <v-icon icon="mdi-circle" class="border-md rounded-circle" :color="color" size="x-large"></v-icon>
          </v-btn>
          </template>
          <v-color-picker hide-inputs v-model="SVGColorMap[id]" show-swatches >
            
          </v-color-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="ma-0 pa-1 " align="start" v-if="selectedObjectType === 'path'">
          <v-menu>
          <template v-slot:activator="{ props }">
          <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
            <v-icon icon="mdi-circle" class="border-md rounded-circle" :color="SHAPEFILL" size="large"></v-icon>
            FILL
          </v-btn>
          </template>
          <v-color-picker hide-inputs show-swatches v-model="SHAPEFILL"></v-color-picker>
          </v-menu>
          <v-menu>
          <template v-slot:activator="{ props }">
          <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
            <v-icon icon="mdi-circle" class="border-md rounded-circle" :color="SHAPESTROKE" size="large"></v-icon>
            STROKE
          </v-btn>
          </template>
          <v-color-picker hide-inputs show-swatches v-model="SHAPESTROKE"></v-color-picker>
          </v-menu>
          <v-menu width="200">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-border-all-variant" size="large"></v-icon>
              STROKE Width
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="20" min="1" tickSize="1" class="pa-1" v-model="SHAPESTROKEWIDTH"></v-slider></v-list-item>
            </v-list>
          </v-menu>
          <v-menu width="200">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-border-none-variant" size="large"></v-icon>
              STROKE DASH
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="100" min="0" tickSize="1" class="pa-1" v-model="SHAPESTROKEDASH"></v-slider></v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
 


    <v-navigation-drawer  v-model="drawer" :width="100" permanent app class="pt-6 elevation-4 " v-if="issmAndUp"  >
      <v-btn prepend-icon="mdi-auto-fix" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="showAIImageDialog = true">
      AI IMAGE
      </v-btn>
      <v-btn prepend-icon="mdi-text" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="addText">
      TEXT
      </v-btn>
      <v-btn prepend-icon="mdi-image" class="ma-0" rounded="0" stacked size="x-small" variant="text"  @click="showUploadDialog = true">
      IMAGE
      </v-btn>
      <v-btn prepend-icon="mdi-shape" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="showShapeDialog = true">
      SHAPES
      </v-btn>
      <v-btn prepend-icon="mdi-sticker-emoji" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="showStickerDialog = true">
      Graphics
      </v-btn>
    </v-navigation-drawer>

  <div ref="CanvasContainer" style="overflow-x: scroll; " class="mt-12 pa-0 m-b0 " >
    <div
      ref="CanvasWrapper"
      class="pa-0 ma-0 "
      style=" width: 100%; height: 100%; touch-action: none;"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleObjectSelection"
      @click="handleObjectSelection"
    
    >
      <div ref="guide" class="guidWraper" style="" > 
        <v-img :src="offCanvasGuide"></v-img>
      </div>    
      <canvas ref="canvas" class="	pa-0 ma-0 elevation-2 border-md transparent-indicator"></canvas>
      </div>

</div>

    <!--UPDATE DIALG ABOVE-->
    <StickerDialog :model-value="showStickerDialog" @update:model-value="showStickerDialog = $event" :addGraphicToCanvas="addGraphicToCanvas"/>
    <ShapeDialog :model-value="showShapeDialog" @update:model-value="showShapeDialog = $event" :addGraphicToCanvas="addShapesToCanvas"/>
    <AIImageDialog :model-value="showAIImageDialog" @update:model-value="showAIImageDialog = $event" :addGraphicToCanvas="addImageToCanvas" :userEmail="userEmail"/>
    <UploloadImageDialog :model-value="showUploadDialog" @update:model-value="showUploadDialog = $event" :addGraphicToCanvas="addImageToCanvas"/>
    


    <v-footer
      app
      class="elevation-4 d-flex d-sm-none"
    >
      <v-row class="pa-3">
      <v-btn prepend-icon="mdi-auto-fix" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="showAIImageDialog= true">
      AI IMAGE
      </v-btn>
      <v-btn prepend-icon="mdi-text" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="addText">
      TEXT
      </v-btn>
      <v-btn prepend-icon="mdi-image" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="showUploadDialog = true">
      IMAGE
      </v-btn>
      <v-btn prepend-icon="mdi-shape" class="ma-0" rounded="0" stacked size="x-small" variant="text"  @click="showShapeDialog = true">
      SHAPES
      </v-btn>
      <v-btn prepend-icon="mdi-sticker-emoji" class="ma-0" rounded="0" stacked size="x-small" variant="text" @click="showStickerDialog = true">
      Graphics
      </v-btn>
      

    </v-row>

    </v-footer>


    <v-fade-transition>
    <v-footer
      app
      class="elevation-4 d-flex d-sm-none"
      color="primary"
      v-if="isObjectSelected"
    >
      <v-row>
        <v-col cols="auto" class=" pa-1" align="start">
          <v-btn prepend-icon="mdi-delete" class="ma-0 pa-1" rounded="0" stacked size="x-small" variant="text"  @click="removeSelectedObject">
          Remove
          </v-btn>
          <v-btn prepend-icon="mdi-content-copy" class="ma-0  pa-1" rounded="0" stacked size="x-small" variant="text"  @click="duplicateSelectedObject">
          Duplicate
          </v-btn>
          <v-menu width="210">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0  pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-layers" size="large"></v-icon>
              Position
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn  prepend-icon="mdi-arrange-bring-to-front" class="ma-0" stacked  size="x-small" variant="text" @click="bringFront">To front</v-btn>  
                <v-btn  prepend-icon="mdi-arrange-send-to-back" class="ma-0" stacked size="x-small" variant="text" @click="sendBack">To back</v-btn> 
                <v-btn  prepend-icon="mdi-arrange-bring-forward" class="ma-0" stacked size="x-small" variant="text" @click="bringForward">Forward</v-btn> 
                <v-btn  prepend-icon="mdi-arrange-send-backward" class="ma-0" stacked size="x-small" variant="text" @click="sendBackward">Backward</v-btn>                   
              </v-list-item>
            </v-list>
          </v-menu>
          
        </v-col>

        <v-col cols="auto" class="ma-0 pa-1 " align="start" v-if="selectedObjectType === 'textbox'">
          <v-menu>
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-circle" class="border-md rounded-pill" :color="TEXTCOLOR" size="large"></v-icon>
              COLOR
              </v-btn>
            </template>
            <v-color-picker hide-inputs show-swatches v-model="TEXTCOLOR"></v-color-picker>
          </v-menu>
          <v-menu width="200">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-line-spacing" size="large"></v-icon>
              Line space
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="3" min="0.1" tickSize="0.0001" class="pa-1" v-model="LINEHIGHT"></v-slider></v-list-item>
            </v-list>
          </v-menu>
          <v-menu width="200">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-letter-spacing" size="large"></v-icon>
              Letter space
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="1000" min="-500" tickSize="1" class="pa-1" v-model="CHARSPACE"></v-slider></v-list-item>
            </v-list>
          </v-menu>
          <v-menu >
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-align-center" size="large"></v-icon>
              ALIGN
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn  icon="mdi-format-align-left" class="ma-0"  size="small" variant="text" @click="TEXTALIGN='left'"></v-btn>  
                <v-btn  icon="mdi-format-align-center" class="ma-0"  size="small" variant="text" @click="TEXTALIGN='center'"></v-btn> 
                <v-btn  icon="mdi-format-align-right" class="ma-0"  size="small" variant="text" @click="TEXTALIGN='right'"></v-btn>                   
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu >
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-font" size="large"></v-icon>
              FONT
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
               <!-- <v-btn  icon="mdi-format-bold" class="ma-0"  size="small" variant="text" @click="applyBold"></v-btn>  -->
               <!-- <v-btn  icon="mdi-format-italic" class="ma-0"  size="small" variant="text" @click="applyItalic" ></v-btn> -->
                <v-btn  icon="mdi-format-underline" class="ma-0"  size="small" variant="text" @click="applyUnderLine"></v-btn>  
                <v-menu>
                <template v-slot:activator="{ props }">
                  <v-btn  icon="mdi-format-color-highlight" class="ma-0"  size="small" variant="text"  v-bind="props"></v-btn>  
                </template>
                <v-color-picker hide-inputs show-swatches v-model="HIGHLIGHTCOLOR"></v-color-picker>
                </v-menu>                 
              </v-list-item>
              <v-list-item>
                <v-virtual-scroll
                  :items="fonts"
                  item-height="30"
                  height="300"
                >
                  <template v-slot:default="{ item }">
                    <v-row class="pa-2 cursor-pointer" :style="{ fontFamily: `'${item}'`}"  @click="applyFont(item)">
                      <div class="pl-3	" style="font-size: larger">{{ item }}</div>
                       
                    </v-row>
                  </template>
                </v-virtual-scroll>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-menu width="200">
          <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-format-text-variant-outline" size="large"></v-icon>
              Stroke
              </v-btn>
            </template>
            <v-list >
              <v-list-item>
                <v-menu>
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"   size="small" variant="text" v-bind="props" >
              <v-icon icon="mdi-circle" class="border-md rounded-pill" :color="TEXTSTROKECOLOR" size="large"></v-icon>
               COLOR
              </v-btn>
            </template>
            <v-color-picker hide-inputs show-swatches v-model="TEXTSTROKECOLOR"></v-color-picker>
          </v-menu>

              </v-list-item>
              <v-list-item><v-slider  max="40" min="0" step="1" tickSize="1" class="pa-1" v-model="TEXTSTROKE"></v-slider></v-list-item>
              
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="ma-0 pa-1 " align="start" v-if="selectedObjectType === 'group'">
          <v-menu v-for="(color, id) in SVGColorMap" :key="id" >
          <template v-slot:activator="{ props }">
          <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
            <v-icon icon="mdi-circle" class="border-md rounded-circle" :color="color" size="x-large"></v-icon>
          </v-btn>
          </template>
          <v-color-picker hide-inputs v-model="SVGColorMap[id]" show-swatches >
            
          </v-color-picker>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="ma-0 pa-1 " align="start" v-if="selectedObjectType === 'path'">
          <v-menu>
          <template v-slot:activator="{ props }">
          <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
            <v-icon icon="mdi-circle" class="border-md rounded-circle" :color="SHAPEFILL" size="large"></v-icon>
            FILL
          </v-btn>
          </template>
          <v-color-picker hide-inputs show-swatches v-model="SHAPEFILL"></v-color-picker>
          </v-menu>
          <v-menu>
          <template v-slot:activator="{ props }">
          <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
            <v-icon icon="mdi-circle" class="border-md rounded-circle" :color="SHAPESTROKE" size="large"></v-icon>
            STROKE
          </v-btn>
          </template>
          <v-color-picker hide-inputs show-swatches v-model="SHAPESTROKE"></v-color-picker>
          </v-menu>
          <v-menu width="200">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-border-all-variant" size="large"></v-icon>
              STROKE Width
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="20" min="1" tickSize="1" class="pa-1" v-model="SHAPESTROKEWIDTH"></v-slider></v-list-item>
            </v-list>
          </v-menu>
          <v-menu width="200">
            <template v-slot:activator="{ props }">
            <v-btn   class="ma-0 pa-1"  stacked size="x-small" variant="text" v-bind="props" >
              <v-icon icon="mdi-border-none-variant" size="large"></v-icon>
              STROKE DASH
              </v-btn>
            </template>
            <v-list >
              <v-list-item><v-slider  max="100" min="0" tickSize="1" class="pa-1" v-model="SHAPESTROKEDASH"></v-slider></v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-footer>
    </v-fade-transition>


</template>

<script>
import * as fabric from 'fabric';
import axios from 'axios';
import { useDisplay } from 'vuetify';
import FontFaceObserver from 'fontfaceobserver';
import StickerDialog from '../components/StickerDialog.vue';
import ShapeDialog from '../components/ShapeDialog.vue';
import AIImageDialog from '../components/AIImageDialog.vue';
import UploloadImageDialog from './UploloadImageDialog.vue';


export default {
  name: 'CanvasCm',
  setup(){

  },
  props: {
      userProductID: {
        type: String,
        default: '',
      },
      canvasContainerWidth: {
        type: Number,
        default: 0,
      },
    },
  components: {
    StickerDialog,
    ShapeDialog,
    AIImageDialog,
    UploloadImageDialog,
  },
  data() {
    return {
      menu: false,
      AIimages: [],
      dialog: false,
      selectedImage: null,
      selectedObjectType: null,
      isObjectSelected: false,
      lastDistance: 0,
      isScaling: false,
      issmAndUp: false,
      BGCOLOR:'#fff',
      TEXTCOLOR:'#000',
      LINEHIGHT:3,
      CHARSPACE: 100,
      TEXTALIGN:'left',
      TEXTSTROKE:0,
      TEXTSTROKECOLOR:'#000',
      HIGHLIGHTCOLOR:'#000',
      SHAPEFILL: '#000',
      SHAPESTROKE: '#000',
      SHAPESTROKEDASH:10,
      SHAPESTROKEWIDTH:2,
      fonts: ['Roboto','Twinkle Star','Reenie Beanie','Codystar','Preahvihear','Bungee Hairline','Bangers','Eater','Moo Lah Lah', 'Hanalei','Hanalei Fill','Bungee Outline','Henny Penny','Ceviche One','Fontdiner Swanky','Bigelow Rules','Londrina Shadow','Luckiest Guy','Sue Ellen Francisco','Lacquer','Black And White Picture' ,'Kenia','Autour One','Rampart One','Piedra','Aladin' ,'Bungee Shade','Bowlby One SC' ,'Delicious Handrawn', 'Rubik Wet Paint','New Amsterdam','Abril Fatface','Rajdhani','Saira Condensed','Archivo Black','Pacifico','Pirata One','Updock'], 
      SVGColorMap:{},
      showStickerDialog: false,
      showShapeDialog: false,
      showAIImageDialog:false,
      showUploadDialog: false,
      userEmail: "whycanwebe@gmail.com",
      canvasWidth: 2048,
      canvasHeight: 1000,
      productCanvasguide: '',
      offCanvasGuide:'',
      //for undo redo 
      canvasState: [],
      currentStateIndex: -1,
      undoStatus: false,
      redoStatus: false,
      undoFinishedStatus: true,
      redoFinishedStatus: true,
      isUndoRedoActive: false,


     

    };
  },
  computed: {
    canUndo() {
      return this.currentStateIndex > 0;
    },
  },
  async created(){
    const { smAndUp } = useDisplay();
    this.issmAndUp =smAndUp;

  },
     async mounted() {
      const userProduct = await axios.get(`/apis/getUserProduct/${this.userProductID}`);
      const product = await axios.get(`/apis/product/${userProduct.data.productID}`);
      this.productCanvasguide = this.getImageUrl(product.data.CanvasguideURL)
      this.offCanvasGuide = this.getImageUrl(product.data.OffCanvasGuideURL)
     
      //store product guide image.
      const canvasElement = this.$refs.canvas;
      

      this.canvas = new fabric.Canvas(canvasElement, {
        width: product.data.CanvasMeta.canvasWidth,
        height: product.data.CanvasMeta.canvasHeight, 
      // width:1024,
      // height:1024,
        backgroundColor: '#fff',
        selection: false,
        preserveObjectStacking: true,
      });

      this.enablePinchToZoom();
      await this.loadCanvasData(JSON.parse(userProduct.data.canvasDatas));
      await this.ResizeCanvas(this.canvasContainerWidth + 1);
      await this.ResizeCanvas(this.canvasContainerWidth - 1);

      this.canvas.on('object:added', this.keepGuideOnTop);
      this.canvas.on('object:moved', this.keepGuideOnTop);
      this.canvas.on('object:modified', this.keepGuideOnTop);

      this.canvas.on('object:modified', this.updateCanvasState);
      this.canvas.on('object:deleted', this.updateCanvasState);
      this.canvas.on('object:moved', this.updateCanvasState);
      this.canvas.on('object:added', (event) => {
      const object = event.target;
      if ( object.isGuid) {
        // Skip saving the state if the object is a guide
        return;
      }
      // Otherwise, save the state
      this.updateCanvasState();

    });
    





  },
  methods:{
    async ResizeCanvas(width){
    if(width>1200) {width = 1200}
      if(this.canvas){
      const ratio = this.canvas.getWidth() / this.canvas.getHeight();
      const containerWidth   = width;
      const scale = containerWidth / this.canvas.getWidth();
      const zoom  = this.canvas.getZoom() * scale;
      await this.canvas.setDimensions({width: containerWidth, height: containerWidth / ratio});
      await this.canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
      this.$refs.guide.style.width = `${width}px`;      }
      
    },
    async addImageToCanvas(imageSrc) {
      const image = await fabric.FabricImage.fromURL(imageSrc);
      image.crossOrigin = "anonymous";
      image.scaleX = 0.7; // 50% of original width
      image.scaleY = 0.7; // 50% of original height
      this.canvas.add(image);
      this.canvas.renderAll();
      this.showAIImageDialog = false;
      this.showUploadDialog = false;
      
    },
    async addGraphicToCanvas(imageSrc) {
      const svg = await fabric.loadSVGFromURL(imageSrc);
      const obj = fabric.util.groupSVGElements(svg.objects, svg.options);
      obj.scale(1);
      this.canvas.add(obj);
      this.canvas.renderAll();
      this.showStickerDialog = false;
    },
    async addShapesToCanvas(imageSrc) {
      const svg = await fabric.loadSVGFromURL(imageSrc);
      const obj = fabric.util.groupSVGElements(svg.objects, svg.options);
      obj.scale(1);
      obj.IsShape = true;
      this.canvas.add(obj);
      
      this.canvas.renderAll();
      this.showShapeDialog = false;
    },

    removeSelectedObject(){
      this.canvas.remove(this.canvas.getActiveObject());
      this.isObjectSelected = false;
      this.selectedObjectType = null;
      this.canvas.discardActiveObject();
    },
    async duplicateSelectedObject(){
    // Check if there is an active object
    if (this.isObjectSelected) {
      console.log(fabric.version);
        // Clone the object
        let activeObject = this.canvas.getActiveObject();
        let clonedObj = await activeObject.clone();
        // Offset the cloned object a bit to distinguish it from the original
        clonedObj.set({
                left: activeObject.left + 10,
                top: activeObject.top + 10,
                evented: true
            });
  
            // Add the cloned object to the canvas
            this.canvas.add(clonedObj);
            
            // Set the cloned object as the active selection
            this.canvas.setActiveObject(clonedObj);
            
            
            

            // Re-render the canvas to display the new object
            this.canvas.requestRenderAll();

    } else {
        console.log('No object selected');
    }
      
    },
    bringFront(){
     const activeObject = this.canvas.getActiveObject();
      if(activeObject){
      this.canvas.bringObjectToFront(activeObject);
      this.canvas.requestRenderAll();
     }
     this.keepGuideOnTop();
     this.canvas.requestRenderAll();
    },
    sendBack(){
     const activeObject = this.canvas.getActiveObject();
      if(activeObject){
      this.canvas.sendObjectToBack(activeObject);
      this.canvas.requestRenderAll();
     }
    },
    sendBackward(){
     const activeObject = this.canvas.getActiveObject();
      if(activeObject){
      this.canvas.sendObjectBackwards(activeObject);
      this.canvas.requestRenderAll();
     }
    },
    bringForward(){
     const activeObject = this.canvas.getActiveObject();
      if(activeObject){
      this.canvas.bringObjectForward(activeObject);
     }
     this.keepGuideOnTop();
     this.canvas.requestRenderAll();
    },
    applyBold() {
    const textObject = this.canvas.getActiveObject();
    if (!textObject) return; // Ensure a text object is selected

    const start = textObject.selectionStart;
    const end = textObject.selectionEnd;

    if (start === end) {
      // No selection, toggle bold on the entire text
      const currentStyles = textObject.getSelectionStyles(0, textObject.text.length) || [];
      const isCurrentlyBold = currentStyles.every(style => style.fontWeight === 'bold');

      // Apply or remove bold to the entire text
      for (let i = 0; i < textObject.text.length; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        textObject.setSelectionStyles({
          ...existingStyle,
          fontWeight: isCurrentlyBold ? 'normal' : 'bold'
        }, i, i + 1);
      }
    } else {
      // Apply or remove bold on the selected text range
      let shouldApplyBold = true;

      // Check if the entire selected range is already bold
      for (let i = start; i < end; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        if (existingStyle.fontWeight === 'bold') {
          shouldApplyBold = false;
          break;
        }
      }

      // Apply or remove bold based on the check above
      for (let i = start; i < end; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        textObject.setSelectionStyles({
          ...existingStyle,
          fontWeight: shouldApplyBold ? 'bold' : 'normal'
        }, i, i + 1);
      }
    }
    const originalText = textObject.text;
    textObject.set({ text: originalText + " " }); // Add a space
    textObject.set({ text: originalText });       // Remove the space     
    textObject.setCoords();
    textObject.canvas.renderAll();
  
    },
    applyUnderLine() {
    const textObject = this.canvas.getActiveObject();
    if (!textObject) return; // Ensure a text object is selected

    const start = textObject.selectionStart;
    const end = textObject.selectionEnd;

    if (start === end) {
      // No selection, toggle uderline on the entire text
      const currentStyles = textObject.getSelectionStyles(0, textObject.text.length) || [];
      const isCurrentlyunderlined = currentStyles.every(style => style.underline === true);

      // Apply or remove uderline to the entire text
      for (let i = 0; i < textObject.text.length; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        textObject.setSelectionStyles({
          ...existingStyle,
          underline: isCurrentlyunderlined ? false : true
        }, i, i + 1);
      }
      
    } else {
      // Apply or remove uderline on the selected text range
      let shouldApplyUnderline = true;

      // Check if the entire selected range is already bold
      for (let i = start; i < end; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        if (existingStyle.underline === true) {
          shouldApplyUnderline = false;
          break;
        }
      }

      // Apply or remove bold based on the check above
      for (let i = start; i < end; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        textObject.setSelectionStyles({
          ...existingStyle,
          underline: shouldApplyUnderline ? true : false
        }, i, i + 1);
      }
    }
    const originalText = textObject.text;
    textObject.set({ text: originalText + " " }); // Add a space
    textObject.set({ text: originalText });       // Remove the space     
    textObject.setCoords();
    textObject.canvas.renderAll();
    console.log(textObject);
  
    },
    applyItalic() {
    const textObject = this.canvas.getActiveObject();
    if (!textObject) return; // Ensure a text object is selected

    const start = textObject.selectionStart;
    const end = textObject.selectionEnd;

    if (start === end) {
      // No selection, toggle italic on the entire text
      const currentStyles = textObject.getSelectionStyles(0, textObject.text.length) || [];
      const isCurrentlyItalic = currentStyles.every(style => style.fontWeight === 'italic');

      // Apply or remove italic to the entire text
      for (let i = 0; i < textObject.text.length; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        textObject.setSelectionStyles({
          ...existingStyle,
          fontWeight: isCurrentlyItalic ? 'normal' : 'italic'
        }, i, i + 1);
      }
    } else {
      // Apply or remove italic on the selected text range
      let shouldApplyItalic = true;

      // Check if the entire selected range is already italic
      for (let i = start; i < end; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        if (existingStyle.fontWeight === 'italic') {
          shouldApplyItalic = false;
          break;
        }
      }

      // Apply or remove italic based on the check above
      for (let i = start; i < end; i++) {
        const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
        textObject.setSelectionStyles({
          ...existingStyle,
          fontWeight: shouldApplyItalic ? 'italic' : 'normal'
        }, i, i + 1);
      }
    }
    const originalText = textObject.text;
    textObject.set({ text: originalText + " " }); // Add a space
    textObject.set({ text: originalText });       // Remove the space     
    textObject.setCoords();
    textObject.canvas.renderAll();
  
    },
    applyFont(fontFamily){
      const font = new FontFaceObserver(fontFamily);

      font.load().then(() => {
        const textObject = this.canvas.getActiveObject();
        const currentStyles = textObject.getSelectionStyles(0, textObject.text.length) || [];
        currentStyles.every(style => style.fontFamily = fontFamily);
        textObject.set({ fontFamily: fontFamily });
        // Force re-render of the text object to apply the font immediately
        const originalText = textObject.text;
        textObject.set({ text: originalText + " " }); // Add a space
        textObject.set({ text: originalText });       // Remove the space
        console.log(fontFamily,originalText)
        // Update the object on the canvas
        textObject.setCoords();
        textObject.canvas.renderAll();
      }).catch((e) => {
        console.error(`Font ${fontFamily} could not be loaded.`,e);
      });

    },
    exportCanvas() {
      this.removeMustRemoveObjects();
      // Get the JSON representation of the canvas
      const json = this.canvas.toJSON();
      
      // Convert the JSON object to a string
      const jsonString = JSON.stringify(json);

      // Create a Blob from the JSON string
      const blob = new Blob([jsonString], { type: "application/json" });

      // Create a link element and trigger a download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "canvas.json";
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    },
    async loadCanvasData(canvasData){
     await this.loadFonts();
     await this.canvas.loadFromJSON(canvasData, () => {
     this.canvas.renderAll();
    });
    this.updateCanvasState();
    this.BGCOLOR = this.canvas.background;
    },
    canvasZoomIn(){
        this.canvas.setWidth(this.canvas.getWidth() * 1.2);
        this.canvas.setHeight(this.canvas.getHeight() * 1.2);
        this.canvas.setZoom(this.canvas.getZoom() * 1.2);
        this.$refs.guide.style.width= `${this.canvas.getWidth()}px`;
    },
    canvasZoomOut(){
        this.canvas.setWidth(this.canvas.getWidth() * 0.8);
        this.canvas.setHeight(this.canvas.getHeight() * 0.8);
        this.canvas.setZoom(this.canvas.getZoom() * 0.8);
        this.$refs.guide.style.width= `${this.canvas.getWidth()}px`;
    },
    handleTouchStart(event) {
      // Store the initial touch position
      
      if (event.touches.length === 2) {
        this.isScaling = true;
        this.lastDistance = this.getDistance(event.touches[0], event.touches[1]);
      } else {
        this.lastTouchX = event.touches[0].clientX;
      }
    },
    handleTouchMove(event) {
      event.preventDefault();
      if(!this.isScaling && !this.canvas.getActiveObject() &&  event.touches.length === 1 ){
      const currentTouchX = event.touches[0].clientX;
      // Calculate the difference in touch positions
      const deltaX = this.lastTouchX - currentTouchX;
      // Scroll the container by the difference
      const container = this.$refs.CanvasContainer;
      container.scrollLeft += deltaX;
      // Update the last touch position
      this.lastTouchX = currentTouchX;
      

      }
      else if (this.isScaling && event.touches.length === 2) {
        const currentDistance = this.getDistance(event.touches[0], event.touches[1]);
        const scale = currentDistance / this.lastDistance;

        // Calculate new dimensions while maintaining the aspect ratio
        const newWidth = this.canvas.getWidth() * scale;
        const newHeight = this.canvas.getHeight() * scale;

        // Set limits to canvas size (optional)
        const maxWidth = 5000;  // maximum width
        const minWidth = 100;   // minimum width
        

        if (newWidth <= maxWidth  && newWidth >= minWidth) {
          this.canvas.setWidth(newWidth);
          this.canvas.setHeight(newHeight);
          // Update the zoom level to maintain the content's scale relative to the new size
          const zoom = this.canvas.getZoom() * scale;
          this.canvas.setZoom(zoom);
          this.canvas.requestRenderAll(); // Ensure canvas is re-rendered
          this.$refs.guide.style.width= `${this.canvas.getWidth()}px`;
        }

        // Update the lastDistance for the next movement
        this.lastDistance = currentDistance;
        event.preventDefault();
      }
    
    },
    handleObjectSelection() {
      let activeObject = this.canvas.getActiveObject();
      if(activeObject){
        activeObject.snapAngle = 5;
        this.isObjectSelected = true;
        this.selectedObjectType = activeObject.type;
        if(this.selectedObjectType === 'group'){
          let colorMap = this.assignColorId(activeObject);
          this.SVGColorMap = colorMap;
        }
        if(this.selectedObjectType === 'textbox'){
          console.log(activeObject);
          this.LINEHIGHT = activeObject.lineHeight;
          this.CHARSPACE = activeObject.charSpacing;
          this.TEXTSTROKE = activeObject.strokeWidth;
          this.TEXTSTROKECOLOR = activeObject.stroke;
          
          if(Object.keys(activeObject.styles).length == 0){
            this.TEXTCOLOR = activeObject.fill;
          }          
        }
        if(this.selectedObjectType == 'path'){
          this.SHAPEFILL = activeObject.fill;
          this.SHAPESTROKE = activeObject.stroke;
          this.SHAPESTROKEWIDTH = activeObject.strokeWidth;
          if(activeObject.strokeDashArray){
            this.SHAPESTROKEDASH = activeObject.strokeDashArray[0];
          }else{
            this.SHAPESTROKEDASH = 0;
          }

        }

      } else {
        this.isObjectSelected = false;
      }

    },
   //code ebelow is for handeling touch zoom
    enablePinchToZoom() {
      const canvasEl = this.canvas.upperCanvasEl;
      canvasEl.addEventListener("touchstart", this.handleTouchStart);
      canvasEl.addEventListener("touchmove", this.handleTouchMove);
      canvasEl.addEventListener("touchend", this.handleTouchEnd);
    },

    handleTouchEnd(e) {
      if (e.touches.length < 2) {
        this.isScaling = false;
        this.lastDistance = 0;
      }
    },
    getDistance(touch1, touch2) {
      return Math.sqrt(
        Math.pow(touch2.clientX - touch1.clientX, 2) +
          Math.pow(touch2.clientY - touch1.clientY, 2)
      );
    },
    //recolor functions
    assignColorId(group) {
    if (!group || !group._objects || !Array.isArray(group._objects)) {
        console.error('Invalid group object.');
        return;
    }

    const colorMap = {}; // Using plain object for reactivity
    let colorID = 1;

    // Loop through each object in the group
    group._objects.forEach((obj) => {
        // Extract the color(s) from the object, assuming fill or stroke
        const colors = new Set(); // Using Set to avoid duplicates

        // Check fill and stroke for color
        if (obj.fill) {
            colors.add(obj.fill);
        }
        if (obj.stroke) {
            colors.add(obj.stroke);
        }

        colors.forEach((color) => {
            if (!Object.values(colorMap).includes(color)) {
                colorMap[colorID] = color;
                colorID++;
            }

            // Assign color IDs to the object
            const assignedColorID = Object.keys(colorMap).find(key => colorMap[key] === color);
            if (obj.fill === color) {
                obj.colorID = assignedColorID;
            }
            if (obj.stroke === color) {
                obj.strokeColorID = assignedColorID;
            }
        });
    });

    return colorMap;
    
    },
    async UpdateProduct(){
      this.removeMustRemoveObjects();
      const canvasData = this.canvas.toJSON();
      const jsonString = JSON.stringify(canvasData);
      const url = `/apis/updateProduct/${this.userProductID}`; // Replace with your API endpoint
      const payload = {
        canvasData: jsonString,
      };
      
      try {
        const response = await axios.post(url, payload);
        if(response){
          console.log(response);
        }
      } catch (error) {
        console.error('API Error:', error);
      }
      finally{
      this.$router.push({ name: 'singleProduct', params: { userProductID: this.userProductID} });
      }
    },
    goBack(){
      this.$router.push({ name: 'singleProduct', params: { userProductID: this.userProductID} });
    },
    getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
    },
    showCanvas(){
    console.log(this.canvas.getObjects());
    },
    removeMustRemoveObjects() {
    this.canvas.getObjects().forEach((obj) => {
      if (obj.isGuid) {
        this.canvas.remove(obj);
        this.canvas.renderAll();
      }
    });
   
    },

    keepGuideOnTop() {
      this.canvas.getObjects().forEach((obj) => {
        if (obj.isGuid) {
          this.canvas.bringObjectToFront(obj);
          this.canvas.requestRenderAll();
        }
      })
      },
    async addGuide(svgURL){
      const svg = await fabric.loadSVGFromURL(svgURL);
      const obj = fabric.util.groupSVGElements(svg.objects, svg.options);
      console.log(obj)
      obj.scale(1);
      obj.selectable = false;
      obj.evented = false;
      obj.isGuid = true,
      this.canvas.add(obj);
      this.canvas.renderAll()
    },
    updateCanvasState() {   
      this.removeMustRemoveObjects();
        if(this.isUndoRedoActive ) return;
        if (this.currentStateIndex < this.canvasState.length - 1) {
          this.canvasState = this.canvasState.slice(0, this.currentStateIndex + 1);
        }
        const canvasJson = JSON.stringify(this.canvas.toJSON());
        // Add the new state and update the current index
        this.canvasState.push(canvasJson);
        this.currentStateIndex = this.canvasState.length - 1;
        this.addGuide(this.productCanvasguide);
    },
    async undo() {
      this.removeMustRemoveObjects();
      this.isUndoRedoActive = true; 
      const previousState =  JSON.parse(this.canvasState[this.currentStateIndex - 1]);
      // Clear the canvas to avoid artifact issues
      await this.canvas.loadFromJSON(previousState, () => {
        this.canvas.requestRenderAll();
      });
      this.isObjectSelected = false;
      this.currentStateIndex -= 1;
      this.isUndoRedoActive = false; 
      this.addGuide(this.productCanvasguide);

    },
    addText(){
    const textObject = new fabric.Textbox('Type Here ...', {
    left: 537,
    top: 50,
    width:300,
    fill: '#000000',
    stroke: 'white',        // Set the stroke color
    strokeWidth: 1,
    paintFirst: "stroke",
    editable: true,
    charSpacing: 100,
    originX: 'center', // Center horizontally
    originY: 'top'     // Keep the top aligned to the `top` position
   // Spread options to allow for additional customization
  });

  this.canvas.add(textObject);
  //this.canvas.setActiveObject(textObject);
  this.applyFont('Preahvihear');
  this.canvas.renderAll();

    },
    inserMask(){

    },
    async loadFonts() {
    const fontPromises = this.fonts.map((font) =>
        document.fonts.load(`1em ${font}`)
    );

    await Promise.all(fontPromises);
    console.log('All fonts loaded');
}

  },
  watch:{
    BGCOLOR(newval){
      this.canvas.backgroundColor = newval;
      this.canvas.renderAll();
    },
    TEXTCOLOR(color){
      const textObject = this.canvas.getActiveObject();
      const start = textObject.selectionStart;
      const end = textObject.selectionEnd;

      if (start !== end) {
          // Apply color to the selected text only
          for (let i = start; i < end; i++) {
              let existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
              textObject.setSelectionStyles({
                  ...existingStyle,
                  fill: color
              }, i, i + 1);
          }
      } else {
          // No selection, apply color to the entire text
          textObject.set({ fill: color });

          // Update existing styles to reflect the new fill color
          if (textObject.styles) {
              for (let line in textObject.styles) {
                  for (let char in textObject.styles[line]) {
                      let charStyle = textObject.styles[line][char];
                      charStyle.fill = color; // Set new fill color
                  }
              }
          }
      }

    const originalText = textObject.text;
    textObject.set({ text: originalText + " " }); // Add a space
    textObject.set({ text: originalText });   
    textObject.setCoords();
    this.canvas.renderAll();
    },
    LINEHIGHT(newval){
      this.canvas.getActiveObject().set('lineHeight', newval);
      this.canvas.renderAll();
    },
    CHARSPACE(newval){
      this.canvas.getActiveObject().set('charSpacing', newval);
      this.canvas.renderAll();
      console.log(newval);
    },
    TEXTALIGN(newval){
      this.canvas.getActiveObject().set('textAlign', newval);
      this.canvas.renderAll();
      //todo handle styles add fill for each style or remove styles
    },
    HIGHLIGHTCOLOR(newval){
      const textObject = this.canvas.getActiveObject();
      if (!textObject) return; // Ensure a text object is selected

      const start = textObject.selectionStart;
      const end = textObject.selectionEnd;

      if (start === end) {
        // No selection, apply highlight to the entire text
        for (let i = 0; i < textObject.text.length; i++) {
          const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
          textObject.setSelectionStyles({
            ...existingStyle,
            textBackgroundColor: newval
          }, i, i + 1);
        }
      } else {
        // Apply highlight to the selected text range
        for (let i = start; i < end; i++) {
          const existingStyle = textObject.getSelectionStyles(i, i + 1)[0] || {};
          textObject.setSelectionStyles({
            ...existingStyle,
            textBackgroundColor: newval
          }, i, i + 1);
        }
      }
      const originalText = textObject.text;
      textObject.set({ text: originalText + " " }); // Add a space
      textObject.set({ text: originalText });   
      textObject.setCoords();
      this.canvas.renderAll();
    },
    SVGColorMap: {
      handler(newVal) {
        console.log('SVGColorMap updated:', newVal);
        const myObject = this.canvas.getActiveObject();
        myObject._objects.forEach((obj) => {
        const colorID = obj.colorID;
        if (colorID && newVal[colorID]) {
                  //obj.fill = newVal[colorID];
                  //obj.stroke = newVal[colorID];
                 // obj.set({ fill: newVal[colorID], stroke: newVal[colorID] });
                 obj.set({ fill: newVal[colorID]});
        }
        })
        this.canvas.renderAll();
      },
      deep: true, 
    },
    SHAPEFILL(newval){
      const myObject = this.canvas.getActiveObject();
      myObject.set({fill: newval});
     this.canvas.renderAll();
    },
    SHAPESTROKE(newval){
      const myObject = this.canvas.getActiveObject();
      myObject.set({stroke: newval});
     this.canvas.renderAll();

    },
    SHAPESTROKEWIDTH(newval){
      const myObject = this.canvas.getActiveObject();
      myObject.set({strokeWidth: newval});
     this.canvas.renderAll();
    },
    SHAPESTROKEDASH(newval){
      const myObject = this.canvas.getActiveObject();
      myObject.set({strokeDashArray:[newval]});
     this.canvas.renderAll();
    },
    TEXTSTROKE(newval) {
    const myObject = this.canvas.getActiveObject();
    if (myObject && myObject.type === 'textbox') {
        myObject.set({
            strokeWidth: newval,
            paintFirst: "stroke",
        });
        if(!myObject.stroke){
          myObject.set({
            stroke:'#fff'
          })
        }
        // Mark the object as dirty and re-render the canvas
        myObject.dirty = true;
        this.canvas.requestRenderAll();
    }
    },
    TEXTSTROKECOLOR(newval) {
    const myObject = this.canvas.getActiveObject();
    if (myObject && myObject.type === 'textbox') {

        myObject.set({
            stroke: newval,
        });
        // Mark the object as dirty and re-render the canvas
        myObject.dirty = true;
        this.canvas.requestRenderAll();
    }
    },
   

    
  }  
};

</script>

<style scoped>

@import url('https://fonts.googleapis.com/css2?family=New+Amsterdam&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pirata+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Updock&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Saira+Condensed:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aladin&family=Autour+One&family=Bangers&family=Bigelow+Rules&family=Black+And+White+Picture&family=Bowlby+One+SC&family=Bungee+Hairline&family=Bungee+Outline&family=Bungee+Shade&family=Ceviche+One&family=Codystar:wght@300;400&family=Delicious+Handrawn&family=Eater&family=Fontdiner+Swanky&family=Hanalei&family=Hanalei+Fill&family=Henny+Penny&family=Kenia&family=Lacquer&family=Londrina+Shadow&family=Luckiest+Guy&family=Moo+Lah+Lah&family=Piedra&family=Preahvihear&family=Rampart+One&family=Reenie+Beanie&family=Sue+Ellen+Francisco&family=Twinkle+Star&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');








.empty-space {
  cursor: pointer;
}

.image-thumbnail {
  cursor: pointer;
}

.selected {
  border: 2px dashed rgba(173, 173, 173, 0.692);
}
.guidWraper{
  position: sticky;
}
.middle{
  width: 20px;
  width: 20px;
    left: 150px;
    position: relative;
}
.transparent-indicator {
  width: 100%; /* Adjust width */
  height: 100%; /* Adjust height */
  background-color: transparent; /* Ensure transparency */
  background-image: 
    linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc),
    linear-gradient(45deg, #ccc 25%, transparent 25%, transparent 75%, #ccc 75%, #ccc);
  background-size: 20px 20px; /* Size of checkered squares */
  background-position: 0 0, 10px 10px; /* Offset to create checkerboard effect */
  background-repeat: repeat;
}

</style>