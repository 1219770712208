<template>
<v-container class="limit-width" theme="myCustomTheme">
    <v-dialog v-model="showEmailModal" max-width="600px" persistent transition="dialog-bottom-transition"  >
        <v-card>
            <v-row class="pa-0 ma-0">
                <v-col cols="12 pb-1">
                    <div class="text-h6 pt-4 pl-6 pr-6 pb-0">Protect your privacy<v-icon icon="mdi-shield-check" size="small" color="warning" class="pa-4"></v-icon></div>
                </v-col>
                <v-col cols="12">
                    <div class="text-subtitle-2 pr-6 pt-0 pl-6 pb-4">In order to keep your data safe, we will need to ask for your email address so in the future only you can view you private data.</div>
                </v-col>
                <v-col cols="12">
                    <v-form class="pt-0 pl-6 pr-6 pb-6" @submit.prevent="handleSubmit">
                        <v-text-field type="email" autocomplete="email" @input="removeSpaces" :rules="emailRules" color="primary" v-model="email" density="compact" variant="outlined" label="Your E-mail" prepend-icon=""  prepend-inner-icon="mdi-email" placeholder="example@gmail.com"></v-text-field>
                        <v-checkbox color="primary" v-model="Agreed" class="pa-0 ma-0">
                        <template v-slot:label>
                            <div class="p-0 m-0">
                            I agree with
                            <!--TODO change the url for privacy policy and terms of use-->
                                <a  class="pa-0 ma-0 "
                                    href="https://vuetifyjs.com" 
                                    target="_blank"
                                    v-bind="props"
                                    @click.stop
                                >
                                    terms of use & privacy policy
                                </a>
                            </div>
                        </template>
                        </v-checkbox>
                        <div  align="left">
                            <v-btn :loading="loading" size="large" type="submit" color="primary" :disabled="!areAllRulesMet" prepend-icon="mdi-check" class="ma-1" >Countiue</v-btn>
                            <v-btn size="small" color="primary" class="ma-1" variant="text" @click="closeEmailMocal">Cancel</v-btn>
                       </div>
                    </v-form>
                </v-col>
            </v-row>
        </v-card>
    </v-dialog>
    <v-row class="bg ma-1  rounded" >
        <v-col align="center"  cols="12" sm="7" class="ma-0 pa-0">
            <v-skeleton-loader
             :loading="productLoading"
             type="image"
            >
            <v-carousel
            class="ma-0 pa-0 h-100"
            hide-delimiter-background
            :show-arrows="false"
            v-if="product"
            v-model="carouselIndex">
              <v-carousel-item
              class="ma-0 pa-0"
              v-for="(image, index) in product.Thumbnails"
              :key="index" 
              v-show="true"
              >
              <v-img
                    :maxwidth="200"
                    :src="getImageUrl(image)"
                    rounded
                    color="#f7f1f1"
                    aspect-ratio="1"
                    @click="showImage = true"
                    eager
                    class="outer-img"
                    >


      
              <!-- Image Popup -->

                </v-img>

              </v-carousel-item>
              </v-carousel>
              <teleport to="body">
                <vue-easy-lightbox
                  v-if="showImage"
                  :visible="showImage"
                  :imgs=product.Thumbnails
                  @hide="showImage = false"
                  :index="carouselIndex"
                  :swipeToNavigate="true"
                />
              </teleport>




        </v-skeleton-loader>

        </v-col>
        <v-col cols="12" sm="5">
            <v-row class="pa-xs-0 pa-md-0 ">
                <v-col align="left">
                    <v-skeleton-loader
                     :loading="productLoading "
                     type="paragraph"
                        >
                        <v-row no-gutters>
                            <v-col cols="12">
                            <span class="text-subtitle-1 font-weight-bold">{{ this.product.Name.en }}</span>
                            </v-col>
                            <v-col cols="12">
                            <span class="text-caption">{{ this.product.Description.en }}</span>
                            </v-col>
                            <v-col cols="12">
                              <div class="text-caption font-weight-bold">Availble sizes: <v-chip v-for="(variatn,index) in product.variants.sizes" class="text-caption" :key="index" variant="text" size="x-small" >{{ variatn.size }}</v-chip></div>
                              
                            </v-col>
                            <!--
                            <v-col cols="12">
                             
                            <v-chip 
                            v-for="(tag,index) in product.designTags"
                            :key="index"
                            color="info" variant="outlined" size="x-small" prepend-icon="mdi-pound" class="mr-1" >
                            {{ tag }}
                            </v-chip>
                            </v-col>
                            -->

                        </v-row>    
                    </v-skeleton-loader>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

    <div ref="targetSection" id="target-section"></div>
</v-container>
<v-container class="limit-width pl-6 pr-6 pt-0" >
         <v-row class=" ">
            <v-col align="left">
                <v-form @submit.prevent="handleSubmit">
                  <v-row>
                 

                  <v-col :cols="this.inputImages[1] ? 6 : 12 ">
                    <div class="text-h6">{{inputImages[0]?.title}}</div>
                    <div class="text-caption mb-3">This can be an image of you or any of your loved ones. just remember the face should be fully visible.</div>
                    <v-col cols="12" align="center" class="border-dashed rounded border-md mb-2">
                    <img v-if="croppedImage && !cropDialog" @click="browseFiles(0)" class="rounded-circle border-dashed border-lg pa-2 cursor-pointer" style="max-width: 100px;" :src="this.croppedImage">
                            <v-btn v-if="!croppedImage"
                            color="primary"
                            class="file-input-btn"
                            @click="browseFiles(0)"
                        icon="mdi-file-image-plus-outline"
                        ></v-btn>
                        <div @click="browseFiles(0)" color="link" v-if="!croppedImage" class="text-subtitle-2 cursor-pointer">Click to uplaod</div>
                        <div @click="browseFiles(0)" color="link" v-if="croppedImage" class="text-subtitle-2 cursor-pointer">Click to change</div>
                    </v-col>
                    <div v-if="imageInValid" class="text-caption" color="error">⚠️ Please select an input photo!</div>
                    <v-file-input ref="fileInput"  v-model="image" density="compact" label="Image" variant="outlined" prepend-icon=""  prepend-inner-icon="mdi-camera" accept="image/*"  @change="onFileChange($event, 0)" style="display: none;" ></v-file-input>
                    <ImageCropper
                    :index="0"
                    :modelValue="cropDialog"
                    :imageSrc="imageSrc"
                    @update:modelValue="cropDialog = $event"
                    @crop="(croppedImage) => onCrop(croppedImage, 0)"
                    />
                  </v-col>

                  <v-col :cols="this.inputImages[1] ? 6 : 6 " v-if="inputImages[1]">
                    <div class="text-h6">{{inputImages[1]?.title}}</div>
                    <div class="text-caption mb-3">This can be an image of you or any of your loved ones. just remember the face should be fully visible.</div>
                    <v-col cols="12" align="center" class="border-dashed rounded border-md mb-2">
                    <img v-if="croppedImage1 && !cropDialog1" @click="browseFiles(1)" class="rounded-circle border-dashed border-lg pa-2 cursor-pointer" style="max-width: 100px;" :src="this.croppedImage1">
                            <v-btn v-if="!croppedImage1"
                            color="primary"
                            class="file-input-btn"
                            @click="browseFiles(1)"
                        icon="mdi-file-image-plus-outline"
                        ></v-btn>
                        <div @click="browseFiles(1)" color="link" v-if="!croppedImage1" class="text-subtitle-2 cursor-pointer">Click to uplaod</div>
                        <div @click="browseFiles(1)" color="link" v-if="croppedImage1" class="text-subtitle-2 cursor-pointer">Click to change</div>
                    </v-col>
                    <div v-if="imageInValid1" class="text-caption" color="error">⚠️ Please select an input photo!</div>
                    <v-file-input ref="fileInput1"  v-model="image1" density="compact" label="Image" variant="outlined" prepend-icon=""  prepend-inner-icon="mdi-camera" accept="image/*" @change="onFileChange($event, 1)" style="display: none;" ></v-file-input>
                    <ImageCropper
                    :index="1"
                    :modelValue="cropDialog1"
                    :imageSrc="imageSrc1"
                    @update:modelValue="cropDialog1 = $event"
                    @crop="(croppedImage) => onCrop(croppedImage, 1)"
                    />
                  </v-col>




                </v-row>

                <!--adding befire this for custim file upload-->    
                <v-text-field    v-model="email" density="compact" variant="outlined" label="Your E-mail" prepend-icon=""  prepend-inner-icon="mdi-email" style="display:none"></v-text-field>
                <!--TODO handle in case of canseling file selection-->
                <div class="text-h6" v-if="this.needUserPrompt">2.Describe the Character's Activity</div>
                <div class="text-caption mb-3" v-if="this.needUserPrompt">Describe what your charachter should be doing. You can also click on our suggested prompts.  </div>
                <v-textarea v-if="this.needUserPrompt" :rules="[ rules.required, rules.min, rules.max]" color="primary" v-model="prompt" density="compact" clearable label="Promt" variant="outlined" prepend-icon=""  prepend-inner-icon="mdi-creation" placeholder="Ex. dressed like a superhero riding a bike.  "></v-textarea>
                <div  ref="mainCta" id="main-cta">
                  <v-btn type="submit" size="large" color="primary"  prepend-icon="mdi-creation" class="mt-2 " :loading="loading" >Generate design</v-btn>

                </div>

                
                <!--added this for cropper-->

                <!--added this for cropper-->


                </v-form>
                <v-row class="pt-4">
                <CustomerReview :productID='product.subCategory' v-if="!productLoading" :staffPick="true"></CustomerReview>
                </v-row>
                <v-footer
                app
                :elevate-on-scroll="true"
                class="elevation-4 d-flex d-sm-none"
                v-if="!isCtaVisible"
              >
                <v-row>
                  <v-col col="12" class="ma-0" align="center">
                    <v-btn type="submit" size="large" color="primary"  prepend-icon="mdi-creation" class="mt-2" :loading="loading" variant="outlined" @click="scrollToSection">Personalise</v-btn>
                  </v-col>
                </v-row>
              </v-footer>
        </v-col>
        </v-row>

</v-container>
</template>

<script>
import axios from 'axios';
import ImageCropper from '../components/CroperComponent.vue';
import { mapActions } from 'vuex';
import VueEasyLightbox from "vue-easy-lightbox";
import CustomerReview from '@/components/CustomerReview.vue';
import { trackEvent } from '@/services/amplitudeService';

  export default{
    name: 'GenerateDesign',
    components: { ImageCropper,VueEasyLightbox,CustomerReview },
    data() {
        return {
         email: null,
         image: null,
         prompt: '',
         product: null,
         ptoductId:null,
         productLoading: true,
         //added this for cropper
         imageSrc: null,
         cropDialog: false,
         croppedImage: null,
         //end of  cropper
         showEmailModal: false,
         imageInValid: false,
         Agreed:null,
         loading: false,
         needUserPrompt:false,
         //added for 2nd input image [refactor later]
         croppedImage1: null,
         cropDialog1: false,
         image1: null,
         imageSrc1: null,
         imageInValid1: false,
         showImage:false,
         carouselIndex:0,
         isCtaVisible: false, 
         rules: {
           required: value => !!value || '⚠️ Please enter a valid prompt!',
           min: value => !!value && value.length > 10 || '⚠️ Your prompt is too short',
           max: value => !!value && value.length < 100 || '⚠️ Your prompt is too long',
          },
          emailRules: [
             value => !!value || '⚠️ Email is required.',
             value => /.+@.+\..+/.test(value) || '⚠️ Email must be valid.',
          ],
          inputImages: [],
          };
          
    },
    async created(){
    const productId = this.$route.params.productID;
    let result;
    try{
     result = await axios.get(`/apis/product/${productId}`); //TODO check for invalid product ID and show 404 (no such product)
    }catch(e){
      console.log(e);
    }finally{
      const product = result.data;
      this.product = product;
      this.inputImages = product.inputImages;
      this.needPrompt();
      this.productLoading =false;
    }

  },
  mounted(){
    this.simulateSlide(); // Target only the first carousel
    this.observeMainCta();
  },
  computed: {
    areAllRulesMet() {
      // Iterate through the rules and check if all are met
      return (this.emailRules.every(rule => rule(this.email) === true) && this.Agreed);
    },
  },
  watch: {
    '$route.params.productID'() {
      this.needPrompt(); // Load new data when route changes
    }
  },
  methods:{
    ...mapActions(['login']),
    ...mapActions(['tempRegister']),
    needPrompt(){
      if(this.product.needUserPrompt == 'False'){
      this.prompt = "Prompt not Requiered!";
      this.needUserPrompt = false;
      }else{
        this.prompt = null;
        this.needUserPrompt = true;
      }
    },
    async handleSubmit() {
    trackEvent('CTA CLICKED', { cta_name: "Generate Design"});
     const currentTime = Math.floor(Date.now() / 1000); 
     const token = this.$store.getters.getToken; 
     const exp = this.$store.getters.getExp; 
     this.scrollToSection();

     if(token && exp > currentTime ){
      if(this.areImagesValid() && this.prompt ){
        this.loading =true;
          const formData = new FormData();
          formData.append('prompt', this.prompt);
          formData.append('productId',this.$route.params.productID);
          const blob = this.dataURItoBlob(this.croppedImage);
          formData.append('image', blob, 'croppedImage.jpg'); // Append the Blob with a filename
          if(this.croppedImage1){
            const blob1 = this.dataURItoBlob(this.croppedImage1);
            formData.append('image1', blob1, 'croppedImage1.jpg'); 
          }
        try{
          const response = await axios.post('/apis/addUserPrompt', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `${token}`
          }
        });
        const prompt_id = response.data.record._id;
        trackEvent('Prpmpt added');
        this.$router.push({ name: 'ViewDesigns', params: { promptID: prompt_id, productID: this.$route.params.productID } });
        this.loading = false;
        }catch (error){
          console.error('Error:', error);
          this.loading = false;
        }
      }
     }else if(token && exp < currentTime ){
      this.$router.push({ name: 'loginPage' });
      this.loading = false;
     }else if(this.areImagesValid() && this.email  && this.prompt && this.Agreed){
      this.loading =true;
       const response = await axios.post('/apis/checkUserName',{username: this.email});
       if(response.data.userNameExist){
        //show login popup or redirect to login 
        this.$router.push({ name: 'loginPage'});
       }else{
        //register user and login 

        await this.tempRegister({ username: this.email});
        const token = this.$store.getters.getToken; 
        const formData = new FormData();
        formData.append('prompt', this.prompt);
        formData.append('productId',this.$route.params.productID);
        // Convert base64 to Blob
        const blob = this.dataURItoBlob(this.croppedImage);
        formData.append('image', blob, 'croppedImage.jpg'); // Append the Blob with a filename
        if(this.croppedImage1){
            const blob1 = this.dataURItoBlob(this.croppedImage1);
            formData.append('image1', blob1, 'croppedImage1.jpg'); 
          }
        try {
            const response = await axios.post('/apis/addUserPrompt', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `${token}`
              }
            });
            const prompt_id = response.data.record._id;
            this.$router.push({ name: 'ViewDesigns', params: { promptID: prompt_id, productID: this.$route.params.productID } });
            this.loading = false;
            // redirect to next step
            
          } catch (error) {
            console.error('Error:', error);
            this.loading = false;
          }
        //then create record and redirect
       }

    } else if (!this.email && this.prompt.length>10){
      this.showEmailModal = true;
      trackEvent('Register Modal opened');
    } else if(!this.Agreed && this.prompt.length>10 ){
        this.showEmailModal = true;
        trackEvent('Register Modal opened');
    }
    },
    // added this fro cropper 
    onFileChange(event, index) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        trackEvent('Image Selected');
        const reader = new FileReader();
        reader.onload = (e) => {
          if(index == 0){
          this.imageSrc = e.target.result;
          this.cropDialog = true;
  
          }
          if(index == 1){
            this.imageSrc1 = e.target.result;
          this.cropDialog1 = true;

          }
        };
        reader.readAsDataURL(file);
      }
    },
    onCrop(croppedImage, index) {
    if (croppedImage) {
     
      if (index === 0) {
        this.croppedImage = croppedImage;
        this.imageInValid = false;
      } else if (index === 1) {
        this.croppedImage1 = croppedImage;
        this.imageInValid1 = false;
      }
    }
  },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    browseFiles(index) {
      trackEvent('Browse files');
      if (index == 0){
        this.croppedImage = null;
        this.image = null;
        this.$refs.fileInput.click();
      }
      if(index == 1){
        this.croppedImage1 = null;
        this.image1 = null;
        this.$refs.fileInput1.click();
      }

    },
    removeSpaces() {
      // Remove spaces from the email input value
      this.email = this.email.replace(/\s/g, '');
    },
    closeEmailMocal(){
      trackEvent('Register Modal Closed');
       // this.email=null;
       // this.Agreed=false;
        this.showEmailModal = false;
    },
    getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
    },
    areImagesValid(){
      const numNeeded = this.inputImages.length;
      console.log(numNeeded)
      if(numNeeded == 1){
        if(!this.croppedImage){
          this.imageInValid = true;
          return false;
        }else{
          this.imageInValid = false;
          return true;
        }
      }else if (numNeeded == 2){
        if(!this.croppedImage && !this.croppedImage1){
          this.imageInValid = true;
          this.imageInValid1 = true;
          return false;
        }else if(!this.croppedImage && this.croppedImage1){
          this.imageInValid = true;
          this.imageInValid1 = false;
          return false
        }else if(this.croppedImage && !this.croppedImage1){
          this.imageInValid = false;
          this.imageInValid1 = true;
          return false
        }
        else if(this.croppedImage && this.croppedImage1){
          this.imageInValid = false;
          this.imageInValid1 = false;
          return true
        }

      }
    },
    simulateSlide() {
            
            setTimeout(() => {
              this.carouselIndex = 1; // Moves to the second slide
            }, 500);

            setTimeout(() => {
              this.carouselIndex = 0; // Returns to the first slide
            }, 1000);
          
        },
        observeMainCta() {
      const mainCta = this.$refs.mainCta;

      if (!mainCta) return;

      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            this.isCtaVisible = entry.isIntersecting;
            
          });
        },
        {
          root: null, // Use the viewport as the root
          threshold: 0.1, // Trigger when at least 10% of the CTA is visible
        }
      );

      observer.observe(mainCta);
    },
    scrollToSection() {
      const target = this.$refs.targetSection; // Access the target section using the ref
      if (target) {
        target.scrollIntoView({ behavior: 'smooth' }); // Smooth scroll to the target section
      }
    },
  },

}
</script>

<style scoped> 
.limit-width{
    max-width: 700px;
}
.file-input-btn {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.outer-img {
  position: relative; /* Make the outer v-img a relative container */
}

.inner-img {
  position: absolute; /* Position the inner v-img absolutely within the outer v-img */
  bottom: 0; /* Align to the bottom */
  left: 20px; /* Align to the left */
  transform: translate(-10%, -10%); /* Optional: Slight offset for styling */
  z-index: 2; /* Ensure it appears on top */
}

  
</style>