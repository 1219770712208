import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import store from '@/store/store';


export const initializeAmplitude = () => {

    amplitude.init(process.env.VUE_APP_AMPLITUDE_KEY, {}, {
    
        serverZone: "EU", // Ensure this option is inside the correct configuration object
      });
      const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: process.env.VUE_APP_AMPLITUDE_SAMPLE_RATE
      });
      amplitude.add(sessionReplayTracking);
  
   
  };




  export const trackEvent = (eventName, eventProperties = {}) => {
    // Check the store for a valid token
    const token = store.getters.getToken; // Assuming the token is stored in Vuex under `state.token`
  
    if (token) {
      try {
        // Decode the token to extract the userId (if your token is a JWT)
        const payload = JSON.parse(atob(token.split('.')[1])); // Decode JWT payload

        const userId = payload.username; // Adjust based on your JWT structure
        if (userId) {
          amplitude.setUserId(userId);
        }
      } catch (error) {
        console.error('Invalid token format:', error);
        amplitude.setUserId("guest"); // Fallback if token is invalid
      }
    } else {
      amplitude.setUserId("guest"); // No token found
    }
  
    // Track the event
    amplitude.track(eventName, eventProperties);
  };