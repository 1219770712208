<template>
  <v-app >
    <v-app-bar app class="elevation-0"  v-if="!hideNavigation">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
  
      <v-img
        src="/assets/octa-logo-p.webp"
        alt="Logo"
        max-width="110"
        class="pa-0 ma-0 cursor-pointer"
        @click=" this.$router.push({ name: 'home'});"


      />

      <v-spacer></v-spacer>
      <!--
      <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn
            icon
            color="rgb(var(--v-theme-primary))"
            v-bind="props"
            v-on="on"
          >
            <v-icon>mdi-earth</v-icon>
          </v-btn>
      </template>
      <v-list>
        <v-list-item @click="changeLanguage('en')">
          <v-list-item-title>English</v-list-item-title>
        </v-list-item>
        <v-list-item @click="changeLanguage('de')">
          <v-list-item-title>Deutsch</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  -->
  <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn
            icon
            color="rgb(var(--v-theme-primary))"
            v-bind="props"
            v-on="on"
          >
            <v-icon>mdi-account</v-icon>
          </v-btn>
      </template>
      <v-list>

        <v-list-item v-if="!isLoggedIn">
          <v-btn type="submit" size="small" color="primary" variant="text" prepend-icon="mdi-login" class="mt-2" @click="login" >login</v-btn>
        </v-list-item>
        <v-list-item v-if="isLoggedIn">
          <v-btn type="submit" size="small" color="primary" variant="text" prepend-icon="mdi-logout" class="mt-2" @click="logOut" >logout</v-btn>
        </v-list-item>

      </v-list>
    </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      app
      temporary
      
    >
      <v-list>
        <v-list-item>
          <v-row class="pa-2">
            <v-col cols="6" class=" pa-1">
              <v-card  color="info" class="rounded-lg elevation-0 cursor-pointer" >
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pt-1"><div class="text-h5">😍</div></v-col>
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pb-1">Valentine</v-col>
              </v-card>
            </v-col>
            <v-col  cols="6" class=" pa-1">
              <v-card  color="primary" class="rounded-lg elevation-0 cursor-pointer">
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pt-1"><div class="text-h5">🎄</div></v-col>
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pb-1">X-mass</v-col>
              </v-card>
            </v-col>
            <v-col  cols="6" class=" pa-1">
              <v-card  color="info" class="rounded-lg elevation-0 cursor-pointer">
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pt-1"><div class="text-h5">😈</div></v-col>
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pb-1">Bachelor</v-col>
              </v-card>
            </v-col>
            <v-col  cols="6" class=" pa-1">
              <v-card  color="info" class="rounded-lg elevation-0 cursor-pointer">
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pt-1"><div class="text-h5">👯</div></v-col>
                <v-col cols="12" align="left" class="ma-0 pa-0 pl-2 pb-1">For besties</v-col>
              </v-card>
            </v-col>
          </v-row>
        </v-list-item>
        <v-divider class="ma-5"></v-divider>
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          :to="item.to"
          router
          @click="drawer = false" 
        >
          <v-list-item-content color="info">
            <div class="text-subtitle-2	" align="left">{{ item.title }}</div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main >
      <v-container class="pa-0" fluid >
        <router-view></router-view>

      </v-container>

    </v-main>
    <v-footer class="bg-info mt-8" v-if="showFooter">
          <v-container class="limited-width">
          <v-row align="left" no-gutters>
          <v-col cols="6" md="4" align="left">
            <v-row gutters>
              <v-col cols="12">
              <div class="text-h6 ">Company</div>
              <div class="text-subtitle-2 cursor-pointer">About</div>
              <div class="text-subtitle-2 cursor-pointer">Contact Us</div>
            </v-col>
            <v-col cols="12">
              
            </v-col>
            </v-row>
          </v-col>
          <v-col cols="6" md="4"  align="left">
            <v-row gutters>
            <v-col cols="12">
              <div class="text-h6 ">Legal</div>
              <div class="text-subtitle-2 cursor-pointer">Imprint</div>
              <div class="text-subtitle-2 cursor-pointer">Privacy Policy</div>
              <div class="text-subtitle-2 cursor-pointer">Return Policy</div>
              <div class="text-subtitle-2 cursor-pointer">Terms & Conditions</div>
            </v-col>
          </v-row>
          </v-col>
          <v-col cols="12" md="4"   align="left">
            <v-row gutters>
            <v-col cols="12">
              <div class="text-h6 ">Social</div>
              <v-row no-gutters="">
                <v-icon icon="mdi-facebook" @click="externalRedirect('https://www.facebook.com/loonifyAI')" ></v-icon>
                <v-icon icon="mdi-twitter" @click="externalRedirect('https://x.com/Loonify_ai')" class="ml-2"></v-icon>
                <v-icon icon="mdi-instagram" @click="externalRedirect('https://www.instagram.com/loonify_ai')" class="ml-2"></v-icon>
                <v-icon icon="mdi-linkedin" @click="externalRedirect('https://www.linkedin.com/company/loonify-ai/')" class="ml-2"></v-icon>
                

              </v-row>
            </v-col>
          </v-row>
          </v-col>
          <v-col align="center" class="text-center mt-4" cols="12">
            {{ new Date().getFullYear() }}  <strong>Loonify UG</strong>
          </v-col>
        </v-row>
       </v-container>

      </v-footer>
  </v-app>
</template>

<script>
import { mapGetters, mapActions  } from 'vuex';
export default {
  name: 'App',
  methods: {
    ...mapActions(['logout']),
    changeLanguage(lang) {
      this.$router.push({ path: `/${lang}` })
      this.$i18n.locale = lang
    },
  
    login(){
      this.$router.push({ name: 'loginPage'});
    },
    logOut(){
      this.logout();
      this.$router.push({ name: 'home'});
    },
    externalRedirect(url){
      window.open(url, '_blank');
    }
  },
  data() {
    return {
      languages: {
        en: 'English',
        es: 'Japanese'
      },
      drawer: false,

      items: [
        { title: 'Home', to: '/' },
        { title: 'About', to: '/about' },
        { title: 'Products', to: '/products' },
        { title: 'Generate Designs', to: '/GenerateDesign/66a4dcf32d95d624301248a3' } //TODO remove this
      ]
    }
  },
  watch: {
    '$route.params.lang': {
      immediate: true,
      handler(lang) {
        if (lang) {
          this.$i18n.locale = lang
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    // Computed property to determine if the navigation should be hidden
    hideNavigation() {
      return this.$route.name === 'EditDesignPage';
    },
    showFooter() {
      // List of routes where the footer should be hidden
      const hiddenFooterRoutes = ['EditDesignPage'];

      // Check if the current route name is in the list
      return !hiddenFooterRoutes.includes(this.$route.name);
    },

  },


}
</script>



<style>
.limited-width {
  max-width: 1192px !important;
  margin: 0 auto !important; /* Center the container */

}

#app {
  width: 100%;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  
}
body{
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
}


h2 {
  
  font-style: normal;
  display: block;
  font-size: 3.25em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  line-height: normal;
}
h3{
  display: block;
  font-size: 1.9em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  line-height: normal;
}
h4{
  font-size: 1.5rem;
  display: block;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  unicode-bidi: isolate;
  line-height: normal;
}
p {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    unicode-bidi: isolate;
    -webkit-font-smoothing: antialiased;
    font-family: "Ubuntu", sans-serif;
    text-rendering: optimizelegibility;
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    line-height: 1.625rem;
    line-height: normal;
}
.text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6, .text-subtitle-1, .text-subtitle-2, .text-body-1, .text-body-2, .text-caption, .text-overline {
  font-family: "Ubuntu", sans-serif;
  unicode-bidi: isolate;
    -webkit-font-smoothing: antialiased;
    font-family: "Ubuntu", sans-serif!important;
    text-rendering: optimizelegibility;
}
.vel-img {
  background-color: rgb(255, 255, 255)!important;
  }
  
</style>
