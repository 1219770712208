<template>
  <div class="bg--primary">
    <h2>This is an about page</h2>
  </div>
  <div>
    <h3>This is an about page</h3>
  </div>
  <div>
    <h4>This is an about page</h4>
  </div>
  <div>
    <p>This is an about page</p>
  </div>
</template>
<script>


</script>
<style scoped> 
h2{
  color: rgb(var(--v-theme-info));
}
h3{
  color: rgb(var(--v-infoColor));
}
</style>