  <template>
       <!-- <v-img :src="finalBase64Image" :aspect-ratio="aspectRatio"  v-show="true"/> -->

    <!-- Image Thumbnail -->
    <v-img
      :src="finalBase64Image"
      :aspect-ratio="aspectRatio"
      v-show="true"
      @click="showImage = true"
      style="cursor: pointer;"
    />

    <!-- Image Popup -->
    <teleport to="body">
      <vue-easy-lightbox
        v-if="showImage"
        :visible="showImage"
        :imgs="[finalBase64Image]"
        @hide="showImage = false"
      />
    </teleport>


  </template>
  
  <script>
  import { markRaw } from 'vue';
  import * as THREE from 'three';
  import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

  //added for lighting 
    import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
    import { PMREMGenerator } from 'three';
    import hdrpath from '../assets/studio_small_08_1k.hdr'; 
    import * as fabric from 'fabric';
    import VueEasyLightbox from "vue-easy-lightbox";
    
  
  export default {
    name: 'ProductMockup',
    props: {
      base64Image: {
        type: String,
        required: true,
      },
      modelPath: {
        type: String,
        required: true,

      },
      aspectRatio: {
        type: Number,
        default: 1,
      },
      currentImageData:{
        type: Object,
        required: true,
      },
      MainMaterialLayername:{
        type: String,
        required: true,
      },
      MainMaterialWidth:{
        type: Number,
        required: true,
      },
      MainMaterialHeight:{
        type: Number,
        required: true,
      },
      is3d:{
        type: Boolean,
        required: true,
   
      },
      canvasJson:{
        type: Object,
        required: true,
      },


    },
    components: { VueEasyLightbox },
    data() {
      return {
        model: null,
        scene: markRaw(new THREE.Scene()),
        camera: {},
        renderer: null,
        finalBase64Image: '',
        showImage: false, // Controls the visibility of the popup
        hdrpath,
        currentImageDatatest:      {
        PerspectiveCamera: { fov: 1, aspect: 1, near: 1, far: 1000 },
        renderer: {
          width: 1000,
          height: 1000,
          color: 0,
          alpha: 0.00,
          toneMappingExposure: 0.35
        },
        texture: {
          colorSpace: 'srgb',
          minFilter: 1005,
          wrapS: 1000,
          wrapT: 1000,
          flipY: false
        },
        modelPosition: { x: 0.03, y: -0.83, z: 0 },
        modelRotation: { x: 0, y: -2.2, z: 0 },
        cameraPosition: { x: 0, y: 0, z: 9 },
        cameraRotation: { x: 0, y: 0, z: 0 },
        directionalLight: { color: 16777215, intensity: 8, x: 0.13, y: 0.37, z: 0.4 },
        ambientLight: { color: 16777215, intensity: 0 },
      },
      localCanvasJson: '',

      };
    },
    created(){
      if(this.is3d){
        this.camera = markRaw(new THREE.PerspectiveCamera(this.currentImageData.PerspectiveCamera.fov, this.currentImageData.PerspectiveCamera.aspect, this.currentImageData.PerspectiveCamera.near, this.currentImageData.PerspectiveCamera.far));
      }
    },
     async mounted() {
      if(this.is3d){
      this.renderer = markRaw(new THREE.WebGLRenderer({ antialias: true }));
      this.renderer.setSize(this.currentImageData.renderer.width, this.currentImageData.renderer.height); // Adjust size as needed
      this.renderer.colorSpace = "srgb";
      this.renderer.toneMapping = THREE.LinearToneMapping;
      this.addNeutralEnvironment();
      this.loadModel();
      }
      else{
        this.finalBase64Image = await this.generateBase64FromCanvasJson();
      }



    },
  
    methods: {
      async loadModel() {
        const loader = new GLTFLoader();
        loader.load(this.modelPath,  (gltf) => {
           this.model =  markRaw(gltf.scene); // Mark the model as non-reactive
           this.scene.add(this.model);
           this.updateTextureAndRender(this.base64Image,this.MainMaterialLayername);
        });
      },
      async loadTextureFromBase64(base64Image) {
        console.log(base64Image);
        return new Promise((resolve) => {
          const image = new Image();
          image.src = base64Image;
          image.onload = () => {
             const texture = new THREE.Texture(image);
             texture.colorSpace = this.currentImageData.texture.colorSpace;
             texture.minFilter = this.currentImageData.texture.minFilter;
             texture.wrapS= this.currentImageData.texture.wrapS;
             texture.wrapT= this.currentImageData.texture.wrapT;
             texture.flipY= this.currentImageData.texture.flipY;
             texture.needsUpdate = true;
            resolve(texture);
          };
        });
      },
      async updateTextureAndRender(base64Image,layerName) {
        const texture = await this.loadTextureFromBase64(base64Image);
     
        this.model.traverse((child) => {
            child.children.forEach((subChild) => {
                if(subChild.name === layerName){    //"Cylinder007_2"     MainMugCut_1            
                  subChild.material.map = texture;
                    subChild.needsUpdate = true;
                }
            });

        });
        this.renderScene();
        this.finalBase64Image = this.getCanvasImage();
      },
      async renderScene() {
        this.model.position.set(this.currentImageData.modelPosition.x,this.currentImageData.modelPosition.y,this.currentImageData.modelPosition.z);
        this.model.rotation.set(this.currentImageData.modelRotation.x,this.currentImageData.modelRotation.y,this.currentImageData.modelRotation.z);
        this.camera.position.set(this.currentImageData.cameraPosition.x, this.currentImageData.cameraPosition.y, this.currentImageData.cameraPosition.z); // Adjust as needed
        this.camera.rotation.set(this.currentImageData.cameraRotation.x, this.currentImageData.cameraRotation.y, this.currentImageData.cameraRotation.z); // Adjust as needed
       // this.renderer.setClearColor(this.currentImageData.renderer.color, this.currentImageData.renderer.alpha); // Transparent background
       this.renderer.setClearColor(this.currentImageData.renderer.color, 0); 
        this.renderer.render(this.scene, this.camera);
      },
      getCanvasImage() {
        return this.renderer.domElement.toDataURL('image/png');
      },
      addNeutralEnvironment() {
        const light = new THREE.DirectionalLight(this.currentImageData.directionalLight.color, this.currentImageData.directionalLight.intensity);
        light.position.set(this.currentImageData.directionalLight.x, this.currentImageData.directionalLight.y, this.currentImageData.directionalLight.z);
        this.scene.add(light);
        const ambientLight = new THREE.AmbientLight(this.currentImageData.ambientLight.color,this.currentImageData.ambientLight.intensity );
        this.scene.add(ambientLight);
        this.renderer.toneMappingExposure = this.currentImageData.renderer.toneMappingExposure;
        const pmremGenerator = new PMREMGenerator(this.renderer);
        pmremGenerator.compileEquirectangularShader();
        const rgbeLoader = new RGBELoader();
            rgbeLoader.load(hdrpath, (texture) => {
                const envMap = pmremGenerator.fromEquirectangular(texture).texture;
                this.scene.environment = envMap;
                texture.dispose();
                pmremGenerator.dispose();
            });
      },
      async generateBase64FromCanvasJson() {
      
        this.localCanvasJson = this.currentImageData;
        console.log(this.base64Image);
        this.localCanvasJson.objects = this.localCanvasJson.objects.map(obj => {
      // Check for the object with design: true
          if (obj.design === true) {
            obj.src = this.base64Image; // Update the src property
          }

          return obj;
        });
        const canvas = new fabric.Canvas(null, {
              width: 1024, // Specify your desired width
              height: 1024, // Specify your desired height
            });
            await canvas.loadFromJSON(this.localCanvasJson);
            canvas.renderAll();
            const base64Image = await canvas.toDataURL({
                format: 'png', // Change to 'jpeg' if you need JPEG format
                  // Quality factor (only applies to JPEG)
              });

          return base64Image;

        }

    },
    watch: {
    base64Image() {
    this.loadModel()
  },


},
    
  };
  </script>
  


<style >
/* Ensure the overlay covers the entire viewport */
.vue-easy-lightbox-wrapper {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: 9999 !important; /* Ensure it's above all other components */
}

/* Optional: Add a background overlay for better visibility */
.vue-easy-lightbox-overlay {
  background: rgba(237, 34, 217, 0.8); /* Dark semi-transparent background */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9998;
}
</style>

  