<template>
   
   <v-container class="limited-width">
    <v-row>
      <v-col class="mb-2">
      <div class="text-h5 text-uppercase " align="left">How it works?</div>
      </v-col>
    </v-row>

      <v-card max-width="600" class="pa-3 rounded-xl" elevation="0" color="#fefafa">
        <v-row no-gutters>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col cols="12" >
            <v-row no-gutters>
            <v-col cols="9">
              <v-img 
              aspect-ratio="1"
              class="rounded-circle border-lg border-primary"
              src="/productImages/person3.jpg"
              ></v-img>

            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <v-icon icon="mdi-chevron-right" size="x-large" color="primary"></v-icon>
            </v-col>
            </v-row>
            <v-col cols="9" class="ma-0 pa-0">
              <div class="text-subtitle-2	" align="center">
              Upload a photo
            </div>
            </v-col>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col cols="12" >
            <v-row no-gutters>
            <v-col cols="9">
              <v-img 
              aspect-ratio="1"
              class="rounded-circle border-lg border-primary"
              src="/productImages/darling-oversize-t-female-closeup.png"
              ></v-img>

            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <v-icon icon="mdi-chevron-right" color="primary" size="x-large" ></v-icon>
            </v-col>
            </v-row>
            <v-col cols="9" class="ma-0 pa-0">
              <div class="text-subtitle-2	" align="center">
              Preview your design
            </div>
            </v-col>
          </v-col>
        </v-row>

      </v-col>
      <v-col cols="4">
        <v-row no-gutters>
          <v-col cols="12" >
            <v-row no-gutters>
            <v-col cols="9">
              <v-img 
              aspect-ratio="1"
              class="rounded-circle border-lg border-primary"
              src="/productImages/Hoodie-Darling-anime-withmodel.png"
              ></v-img>

            </v-col>
            <v-col cols="3" class="d-flex align-center">
              <v-icon icon="mdi-check" color="primary" size="large" ></v-icon>
            </v-col>
            </v-row>
            <v-col cols="9" class="ma-0 pa-0">
              <div class="text-subtitle-2	" align="center">
              Choose size & color
            </div>
            </v-col>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
    </v-card>

    <v-row>
      <v-col class="mb-2 mt-4">
      <div class="text-h5 text-uppercase " align="left">{{ this.decodedTag}}</div>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="6" sm="auto" v-for="(product, index) in this.products"
      :key="product._id"
      class="ma-0 pa-1">
      <ProductCard :product="product" :wigle="index === 0"></ProductCard>
      </v-col>
    </v-row>
   </v-container>
</template>




<script>
    import ProductCard from '@/components/ProductCard.vue';
    import axios from 'axios';
    export default {
      name: 'ProductsPage',
      components:{
        ProductCard,
      },
      setup(){
      },
      data(){
        return{
          products: [
        ],
        productTag:'inspired by TV series',
        decodedTag : null,
        productStyle:null,
        }
      },
      created(){
        const { ProductTag } = this.$route.params;
        const decodedTag = decodeURIComponent(ProductTag);
        this.decodedTag = decodedTag;
        this.fetchProducts(decodedTag);
      },
      mounted(){
       
      },
      methods:{
        async fetchProducts(productTag, productStyle) {
            try {
                // Call your API using Axios
                const response = await axios.post('/apis/getProductsCategory', {
                productTag: productTag, // Data sent in the body
                productStyle: productStyle,
                });

                // Set the products from the API response
                this.products = response.data.products;
                //TODO: check for no response and rerout to 404
            } catch (err) {
                // Handle errors and set the error message
                this.error = 'Error fetching products: ' + err.response.data;
            }
            },
      }
    
    }
    </script>

<style></style>

