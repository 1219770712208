<template>
    <v-container>
        <v-row
         class="d-flex align-left justify-center"
         
         >
        <v-card
        min-width="500"
        align="left"
        class="pa-10"
        >
        <v-row>
            <v-col>
                <h4>Imprint</h4>
                <h5>Information according to § 5 DDG</h5>
                <p><br>Lunify UG<br>Address<br>10117 Berlin<br>VAT ID Number: DE1111118<br><br>Commercial Register: HRB 12345566 B<br>Register Court: Amtsgericht Charlottenburg<br><br>Represented by the managing director:<br>Saman Salami<br></p>
                <h4>Contact</h4>
                <p><br><br>There is no customer hotline. Please send any customer inquiries to support@loonify.ai<br>E-Mail: support@loonify.ai<br><br>Responsible for the content displayed according to Section 18 (2) MStV:<br><br>Saman Salami<br>Loonify UG<br>Street Address<br>10117 Berlin</p>

            </v-col>
        </v-row>
        </v-card>
        </v-row>
    </v-container>
</template>
<script>
</script>