  <template>
    <div ref="confettiContainer" class="confetti-container"></div>
  </template>
  
  <script>
import confetti from "canvas-confetti";
  
  export default {
    
    name: "ConfettiShow",
    methods: {
      triggerConfetti() {
        // Confetti configuration
        const end = Date.now() + 1 * 2000; // Show confetti for 1 second
  
        const interval = setInterval(() => {
          if (Date.now() > end) {
            return clearInterval(interval);
          }
  
          confetti({
            particleCount: 200,
            startVelocity: 30,
            spread: 360,
            ticks: 60,
            origin: {
              x: Math.random(),
              y: Math.random() - 0.2,
            },
          });
        }, 200);
      },
    },
    mounted() {
      // Optional: Trigger confetti automatically when the component is mounted
      this.triggerConfetti();
    },
  };
  </script>
  
  <style scoped>
  .confetti-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Confetti won't block clicks */
    z-index: 9999; /* Ensure it's above other elements */
  }
  </style>