<template>

    <v-dialog v-model="internalModelValue" persistent max-width="800">
     <v-card >
      <v-card-text >
            <v-row>
                <v-col cols="6" align="start">
                    <div class="text-subtitle-2">Insert images</div>
                </v-col>
                <v-col cols="6" align="end">
                    <v-btn color="primary" icon="mdi-close"  @click="closeDialog" size="x-small" variant="outlined"></v-btn>
                 </v-col>
            </v-row>
            <v-row class="border-thin border-dashed	">
                <v-file-input v-model="newImage" ref="fileInput" accept="image/*"  label="File input" style="display: none;" @change="onFileChange($event)" ></v-file-input>
                <v-col  cols="12" align="center"><v-btn color="primary" prepend-icon="mdi-image" @click=" browseFiles()" :loading="imagesLoading">Choose file</v-btn><div class="text-subtitle-2 ma-2" color="info">Choose an image file to uplaod</div></v-col>
            </v-row>
            <v-skeleton-loader
                type="image"
                class="d-flex mt-4"
                :loading="imagesLoading"
                >
            <v-row class="mt-4">
                <v-vol
                @click="selectImage(image.imageUrl)"
                cols="3"
                v-for="(image) in Images"
                :key="image._id"
                class="image-container mt-2"
                >

                <v-img
                class="ma-1 "
                width="100"
                aspect-ratio="1"
                cover
                
                :src="this.getImageUrl(image.imageUrl) ">

                <div class="overlay">
                <v-icon color="white" size="36">mdi-plus</v-icon>
                </div>
                </v-img>
                
                

            </v-vol>
            </v-row>
        </v-skeleton-loader>
      </v-card-text>

    </v-card>




    </v-dialog>

  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: {
      modelValue: {
        type: Boolean,
        required: true,
      },
       addGraphicToCanvas: Function,
    },
    async created(){
     
        const token = this.$store.getters.getToken; 
       
        const result = await axios.post('/apis/getUserUploads', null, {
            headers: {
                authorization: token
            }
        });
        this.Images = result.data;
        this.imagesLoading=false;

        
    
    },
    data() {
      return {
        internalModelValue: this.modelValue,
        Images: {},
        newImage: null,
        imagesLoading:true,
      
      };
    },
    watch: {
      modelValue(newVal) {
        this.internalModelValue = newVal;
      },
      internalModelValue(newVal) {
        this.$emit('update:model-value', newVal);
      },
    },
    methods: {
      closeDialog() {
        this.internalModelValue = false;
      },
    selectImage(imageSrc) {
      this.addGraphicToCanvas(imageSrc);
    },
    getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
    },
    browseFiles() {
 
        this.newImage = null;
        this.$refs.fileInput.click();

    },
    onFileChange(event) {
      this.imagesLoading=true;
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          this.newImage = e.target.result;
          await this.uploadImage(file);
        };
        reader.readAsDataURL(file);
      }
    },
    async uploadImage(file) {
        const token = this.$store.getters.getToken; // Get the authorization token
        // Create form data to send the image file
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post('/apis/uploadImage', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data', // Set header for file upload
                    'Authorization': token // Pass the token for authorization
                }
            });
            this.Images = response.data;
            this.imagesLoading=false;
        
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }
    },

  };
  </script>
  <style scoped>
  .image-container {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease;
  }
  .image-container:hover {
    transform: scale(1.05);
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5); /* Light overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .image-container:hover .overlay {
    opacity: 1;
  }
  </style>
  