    <template>
    <div>
      <Confetti ref="confettiEffect" />
    </div>
    <v-row
    class="d-flex align-center justify-center"
    style="min-height: 100vh"
    >
        <v-card
        class="w-75 rounded-xl elevation-12 gradient-card pb-10"
        max-width="600"
        :variant="flat"
        color="primary"
        style="overflow: visible"
        >
        <v-row>
            <v-col cols="12" align="center" class="position-absolute" style="top:-150px">
                <v-img
                :src="getImageUrl('/productImages/parcel-1.webp')"
                width="300"
                 class="image-up"
                  style="overflow: visible"
                >
                </v-img>
            </v-col>
            <v-col cols="12" class="mt-15 pt-10 pl-10 pr-10">
                <div class="text-h3" >Thank You!</div>
                <div class="text-subtitle-1">We have recieved your order. You will recive delivery updates and your payment recipe right in your inbox.</div>
            </v-col>

           <v-col cols="12"  class="no-gutters">
            <v-row>
                <v-col cols="2"></v-col>
                <v-col cols="8">
                    <v-row>
                    <v-col cols="4" align="right"><v-icon icon="mdi-facebook"></v-icon></v-col>
                    <v-col cols="4"><v-icon icon="mdi-instagram"></v-icon></v-col>
                    <v-col cols="4" align="left"><v-icon icon="mdi-linkedin"></v-icon></v-col>
                </v-row>
                </v-col>
                <v-col cols="2"></v-col>
                <v-col cols="12">
                <v-row>

                    <v-col cols="12" class="pl-10 pr-10" align="center">
                        <div class="text-subtitle-1">Recive a <v-chip color="success" class elevation-12 variant="flat" >€ 5.00 voucher</v-chip> for your next order by subscribing to our promotional emails.</div>
                    </v-col>
                    <v-col cols="12" class="pl-10 pr-10" align="center">
                        <div class="text-subtitle-1"><v-btn prepend-icon="mdi-email"   variant="outlined">Subscribe</v-btn></div>
                    </v-col>
                </v-row>
            </v-col>
            </v-row>

           </v-col>
        </v-row>
        </v-card>
        </v-row>

  </template>
  
  <script>
  import Confetti from "@/components/Comfetti.vue";
  
  export default {
    components: {
      Confetti,
    },
    methods: {
      showConfetti() {
        this.$refs.confettiEffect.triggerConfetti(); // Manually trigger confetti
      },
      getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            return `${backendUrl}${imageURL}`; // Construct the full URL
            },
    },
  };
  </script>
  <style scoped>
   .gradient-card {
      background: radial-gradient(circle, rgba(255,255,255,0.3) 0%, rgba(0,97,239,1) 100%);
     
    }
</style>
