<template>
    <v-card
          class="ma-0 rounded-xl border-thin "
          max-width="200"
          min-width="140"
          
            @click="viewProduct(product._id)"
          elevation="0"
          color="#fefafa"
        >
        <v-carousel
        v-model="currentSlide"
        class="ma-0 pa-0"
        hide-delimiters
         :show-arrows="false"
          height="180"
          ref="carousel1"
        >
          <v-carousel-item
          class="ma-0 pa-0 h-100"
           v-for="(Image, index) in product.Thumbnails"
           :key="index" 
          v-show="true"
          >
            <v-img fit
            aspect-ratio="1"
            :src="getImageUrl(Image)"
            >
           
            </v-img>
 
          </v-carousel-item>
          </v-carousel>
            <v-row>
            <v-col cols="6" class="mb-0 pb-0 mt-0 pt-2 d-flex align-center ">
            <div class="text-subtitle-2 ma-0 pl-3 mb-0 pb-0 mt-0 pt-0">{{product.Category}}</div>
           
           </v-col>
           <v-col cols="6" class="ma-0 pa-0 pt-2">
           <v-chip >{{ product.unitPrice }} €</v-chip>
           </v-col>
           <v-col cols="12" class="mt-0 pt-0 pb-0">
            <div class="text-h7 ma-0 pl-3 pt-0" align="left">{{ product.Name.en }}</div>
           </v-col>
      
                <v-col>
                    <v-btn variant="text" append-icon="mdi-chevron-right"  color="primary" class="w-100 pa-0 ma-0 pb-3" elevation="0">Make yours</v-btn>
                </v-col>
        
         </v-row>

        </v-card>
</template>
<script>

export default {
  data() {
        return {
          currentSlide:0,

        }
      },
      name: 'HomePageBanner',

      props: {
        product: {
        type: Object,
        required: false,
        },
        wigle: {
        type: Boolean,
        required: false,
        },
        },
        mounted() {
          if(this.wigle){
            this.simulateSlide(); // Target only the first carousel
          }
          
        },
        methods:{
            getImageUrl(imageURL) {
            // Dynamically generate the full image URL using the environment variable
            const backendUrl = process.env.VUE_APP_BACKEND_URL; // Access the backend URL from .env
            console.log(this.product)
            return `${backendUrl}${imageURL}`; // Construct the full URL
            },
            viewProduct(productId){
            this.$router.push({ name: 'GenerateDesign', params: { productID: productId} });
          },
          simulateSlide() {
            
            setTimeout(() => {
              this.currentSlide = 1; // Moves to the second slide
            }, 500);

            setTimeout(() => {
              this.currentSlide = 0; // Returns to the first slide
            }, 1500);
          
        },
        }
    }
</script>