<template>
<v-container>
      <!-- Loading Dialog -->
      <v-dialog v-model="this.paymentCanceled"  persistent width="500">
        <v-card color="primary" dark>
          <v-card-text>
            <v-container>
              <v-row justify="center">
                <v-col cols="12" class="text-center">
                    <v-icon
                    icon="mdi-close-circle-outline"
                    size="100"
                    color="error"
                    >

                    </v-icon>

   
                </v-col>
                <v-col cols="12" class="text-center">
                  Your payment attempt was canceled and your payment method was not charged.
                </v-col>
                <v-col cols="12" class="text-center">
                  <v-btn
                  variant="outlined"
                  size="large"
                  @click="this.paymentCanceled=false"
                  >
                  Okay
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
<v-container class="limit-width">
    <v-row class="bg ma-1  rounded" >
        <v-col align="center"  cols="4" class="ma-0 pa-0">

        <v-carousel
        class="ma0 pa-0"
        hide-delimiters
         :show-arrows="false"
         height="140"
        v-model="carouselIndex">
            <v-carousel-item
            class="ma-0 pa-0"
            
            v-for="(data, index) in currentImageData"
            :key="index"
            v-show="true"
            >
                <product-mockup
                 :key="data"
                :base64Image="product.base64Material"
                :modelPath="modelPath"
                :aspect-ratio="1"
                :currentImageData="data"
                :MainMaterialLayername = MainMaterialLayername
                :MainMaterialWidth = MainMaterialWidth
                :MainMaterialHeight = MainMaterialHeight
                :is3d="this.baseProduct.Is3d"
                />
            </v-carousel-item>
        </v-carousel>

        </v-col>
        <v-col cols="8">
            <v-row class="pa-xs-0 pa-md-0 ">

                <v-col align="left" class="pa-0 ma-0 ml-4">
                    <v-row no-gutters="">

                        <v-col cols="12">
                            <v-skeleton-loader
                            :loading="loading"
                            type="list-item"
                            class=""
                            >
                            <span class="text-subtitle-1 font-weight-bold">{{this.baseProduct.Name.en}}</span>
                            </v-skeleton-loader>
                            </v-col>
                            <v-col cols="12">
                            <v-chip size="small">
                            <v-col cols="4" @click="quantityDown" class="cursor-pointer" align="center"> <v-icon icon="mdi-minus-circle" size="large" color="primary" ></v-icon></v-col>
                            <v-col cols="4" align="center"> <span class="text-subtitle-1" color="primary">{{ quantity }}</span></v-col>
                            <v-col cols="4" @click="quantityUp" class="cursor-pointer" align="center"> <v-icon icon="mdi-plus-circle" size="large" color="primary"></v-icon></v-col>
                           </v-chip>
                           </v-col>
                    
                    </v-row>
                    <v-skeleton-loader
                            :loading="priceLoading"
                            type="list-item-three-line"
                            class=""
                            >
                        <v-row no-gutters>
                            <v-col cols="12">
                            <span class="text-caption">SubTotal: € {{ this.subtotal.toFixed(2) }} Euro</span>
                            </v-col>
                            <v-col cols="12">
                            <span class="text-caption">Shipping: € {{ this.shippingFee.toFixed(2)}} Euro</span>
                            </v-col>
                            <v-col cols="12">
                            <span class="text-subtitle-1 font-weight-bold">Total: € {{ this.total.toFixed(2) }} Euro</span>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row> 
                        </v-skeleton-loader>  
                </v-col>
            </v-row>
        </v-col>
    </v-row>
 
        <v-form ref="form">
            <v-container>
            <v-row>
                <v-col cols="12" align="left" class="">
                        <div class="text-subtitle-1 font-weight-bold"><v-icon size="small" icon="mdi-truck" color="success"></v-icon> Shipping Address</div>
                </v-col>
                <v-col
                cols="6"
                md="4"
                class="pa-1"
                >
                <v-text-field
                    v-model="firstname"
                    autocomplete="given-name"
                    :rules="[() => !!firstname || '⚠️ Please enter your first name.']"
                    label="First name"
                    variant="outlined"
                    density="compact"
                    required
                ></v-text-field>
                </v-col>

                <v-col
                cols="6"
                 md="4"
                 class="pa-1"
                >
                <v-text-field
                    v-model="lastname"
                    autocomplete="family-name"
                    :rules="[() => !!lastname || '⚠️ Please enter your last name.']"
                    variant="outlined"
                    density="compact"
                    label="Last name"
                    required
                ></v-text-field>
                </v-col>

                <v-col
                cols="12"
                md="4"
                 class=" pa-1"
                >
                <v-autocomplete
                ref="country"
                v-model="country"
                :rules="[() => !!country || '⚠️ Please select a country.']"
                :items="countries"
                label="Country"
                required
                browser-autocomplete="on"
                item-title="name"   
                item-value="code"    
                variant="outlined"
                density="compact"
                ></v-autocomplete>
                </v-col>
                <v-col
                cols="12"
                 md="6"
                 class="pa-1"
                >
                <v-text-field
                    v-model="AddressLineOne"
                    autocomplete="address-line1"
                    :rules="[() => !!AddressLineOne || '⚠️ Please enter a valid address.']"
                    variant="outlined"
                    density="compact"
                    label="Address"
                    required
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                 md="6"
                 class="pa-1"
                >
                <v-text-field
                    v-model="AddressLineTwo"
                    autocomplete="address-line2"
                    variant="outlined"
                    density="compact"
                    label="Apartment,suit,etc.(optional)"
                    required
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                 md="6"
                 class="pa-1"
                >
                <v-text-field
                    v-model="postCode"
                     :rules="[() => !!postCode || '⚠️ Please enter a valid postal code.']"
                    autocomplete="postal-code"
                    variant="outlined"
                    density="compact"
                    label="Postal code"
                    required
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                 md="6"
                 class="pa-1"
                >
                <v-text-field
                    v-model="city"
                     :rules="[() => !!city || '⚠️ Please enter a valid city.']"
                    autocomplete="address-level2"
                    variant="outlined"
                    density="compact"
                    label="City"
                    required
                ></v-text-field>
                </v-col>
                <v-col cols="12" align="left" class="">
                        <div class="text-subtitle-1 font-weight-bold"><v-icon size="small" icon="mdi-phone" color="success"></v-icon> Contact details</div>
                </v-col>
                <v-col
                cols="12"
                md="6"
                class="pa-1"
                >
                <v-text-field
                    v-model="phoneNumber"
                    autocomplete="tel"
                    variant="outlined"
                    density="compact"
                    label="Phone number (optional)"
                    
                ></v-text-field>
                </v-col>
                <v-col
                cols="12"
                md="6"
                class="pa-1"
                >
                <v-text-field
                    v-model="email"
                    autocomplete="Email"
                    variant="outlined"
                    density="compact"
                    label="Email"
                    readonly
                ></v-text-field>
                </v-col>
                <v-col cols="12" align="left" class="">
                        <div class="text-subtitle-1 font-weight-bold"><v-icon size="small" icon="mdi-package-variant-closed" color="success"></v-icon> Shipping method</div>
                </v-col>
                <v-skeleton-loader 
                    :loading="this.ShippingMethodsLoading"
                    type="paragraph"
                    class="w-100"
                    >
                    <v-col cols="auto"
                    v-for="(method, index) in this.ShippingMethods" 
                    :key="method.deliveryPromiseId"
                    class="ma-0 pl-1">

                    <v-card
                    :class="['pa-0 ma-0']"
                    :color="selectedMethod === index ? 'success' : 'grey lighten-2'"
                    :variant="selectedMethod === index ? 'tonal' : 'outlined'"
                    @click="selectMethod(index)"
                    >
                        <v-row class="ma-0 pa-0">
                        <v-col cols="2" class="d-flex align-center justify-center ">
                            <v-icon class="pa-0 ma-0" :icon="selectedMethod === index ? 'mdi-check-circle' : 'mdi-circle-outline'" ></v-icon>
                        </v-col>
                        <v-col cols="10" align="left">
                            <div class="text-subtitle-2 font-weight-bold text-uppercase	" align="left">{{method.type}}
                                <v-tooltip :text="method.name"  location="top">
                                <template v-slot:activator="{ props }">
                                    <v-icon v-bind="props" icon="mdi-help-circle-outline"></v-icon>
                                </template>
                                </v-tooltip>
                            </div>
                            <div class="text-subtitle-2 " align="left">{{method.minDeliveryDays}} - {{ method.maxDeliveryDays }} Days</div>
                            <div class="text-body-2 " align="left">€ {{ method.price.toFixed(2) }} Euro</div>
                        </v-col>
                        </v-row>
                    </v-card>  
                    </v-col>
            </v-skeleton-loader>

            </v-row>
            <v-row>
                <v-col cols="12" align="right">
                    <v-btn
                    size="large"
                    color="primary"
                    @click="handelSubmit"
                    prepend-icon="mdi-credit-card-outline"
                    :loading="this.priceLoading || this.ShippingMethodsLoading || this.orderLoading"
                    >
                        Pay now € {{this.total.toFixed(2)}}
                    </v-btn>
                </v-col>

            </v-row>
            </v-container>
        </v-form>
</v-container>
</template>
<script>
    import axios from 'axios';
    import ProductMockup from '../components/ProductMockup.vue'; // Adjust the import path as necessary
    import mugModelPath from '../assets/mug.glb'; // Import the GLB model 
    import bottelModelPath from '../assets/bottel.glb'; // Import the GLB model 
    import { debounce } from 'lodash';
    import countries from '@/assets/countries.json'; 
    import { loadStripe } from '@stripe/stripe-js';
    import { trackEvent } from '@/services/amplitudeService';
    export default{
        name: 'CreateOrder',
        components: {
        ProductMockup,
        },
        data() {
            return {
            images: [],  // This will store the base64 image data converted to URLs
            loading: false,  // Loading state variable
            priceLoading: true,
            modelPath:null,
            product:{},
            selectedProductMockup: null,
            currentImageData:[],
            carouselIndex: 0,
            MainMaterialLayername:'',
            MainMaterialWidth: 10,
            MainMaterialHeight: 10,
            ShippingMethods:[],
            ShippingMethodsLoading: true,
            selectedMethod: null,
            baseProduct:{},
            orderLoading: false,

            subtotal: 0.00,
            shippingFee:0.00,
            total:0.00,

            firstname: '',
            lastname: '',
            email:'',
            country:'DE',
            AddressLineOne:'',
            AddressLineTwo:'',
            state: '',
            city: '',
            postCode: '',
            phoneNumber: '',
            quantity: 1,
            selectedCountry: '',  // This will store the country code (e.g., 'DE')
            countries: countries,
            valid: false,
            stripe: null,
            paymentCanceled: false,



        };
        
    },
    async mounted(){
        this.stripe = await loadStripe('pk_test_51QBZ2kDLhG0kb7XjClZRDouyqayD2653eRqAEF6HIwuoEMjJgALlW5hd64ashphohxu2dqEdXmUSY9ML1MIteESd00p34oRxjd');
       
    },
    
    created() {
      const { userProductID } = this.$route.params;
      
      this.getProduct(userProductID);
      this.getShippingMethods(userProductID,this.quantity,this.country);
      this.paymentCanceled = this.$route.query.paymentCanceled;
     
      
    },
    watch:{
        quantity: {
            handler: debounce(function() {
                this.getShippingMethods(this.product._id, this.quantity, this.country);
               
            }, 500), // Debounce for 500ms
            immediate: true
            },
            country: {
            handler: debounce(function() {
                this.getShippingMethods(this.product._id, this.quantity, this.country);
               
            }, 500), // Debounce for 100ms
            immediate: true
            },
            city: {
            handler: debounce(function() {
                this.getShippingMethods(this.product._id, this.quantity, this.country);
               
            }, 1000), // Debounce for 100ms
            immediate: true
            },
    },
    
    methods: {
      async getProduct(userProductID) {

        this.loading = true;  // Show loading dialog
        const token = this.$store.getters.getToken; 
        try {
          const response = await axios.get(`/apis/getSingleProduct/${userProductID}`, {
          headers: {
          Authorization: token
          }
         });
          this.product = response.data;
          const productResponce = await axios.get(`/apis/product/${this.product.productID}`);
          this.baseProduct = productResponce.data;
          this.currentImageData = productResponce.data.imagesData;
          this.MainMaterialLayername = productResponce.data.MainMaterialLayername;
          this.MainMaterialWidth = productResponce.data.MainMaterialWidth;
          this.MainMaterialHeight = productResponce.data.MainMaterialHeight;
          this.product.base64Material = `data:image/png;base64,${this.product.base64Material}`;
          this.product.base64Image =  `data:image/png;base64,${this.product.base64Image}`;


          const { selectedVariant } = this.$route.params;

          for (let size of this.baseProduct.variants.sizes) {
          if (size.colors) {
            // Loop through each color in the size
            for (let color of size.colors) {
              // Check if the external product ID matches
              if (color.external_product_id === selectedVariant) {
                // Return the imagesData array if found
                this.currentImageData = color.imagesData || [];
               
              }
            }
          }
        }
          





          if(productResponce.data.Category === 'bottles'){
            this.modelPath = bottelModelPath;
          }else if (productResponce.data.Category === 'mugs') {
            this.modelPath = mugModelPath;
          }
        } catch (error) {
          console.error('Error fetching designs:', error);
          this.$router.push({ name: '404BadPage'});
        } finally {
          this.email = this.product.userEmail;
          this.loading = false;  // Hide loading dialog
        }
      },
    editDesign(){
        this.$router.push({ name: 'EditDesignPage', params: { UserProductID: this.product._id} });
    },
    async getShippingMethods(userProductID,quantity){
      const { selectedVariant } = this.$route.params;
      if(!userProductID){return null}
    
      this.priceLoading = true;
      this.ShippingMethodsLoading = true;
      
      const token = this.$store.getters.getToken; 
      try{

        if (!this.country){
        const detectedCountry = await axios.get('/apis/getCountry',
        {
        headers: {
          Authorization: token
          }
        }
        );
        this.country = detectedCountry.data.country;
        }

        const methods = await axios.post('/apis/getQuote',
        {
          userProductID: userProductID,
          destinationCountry: this.country,
          quantity: quantity,
          destinationCity: this.city,
          destinationAddressLine1: this.AddressLineOne,
          destinationAddressLine2: this.AddressLineTwo,
          destinationPostCode: this.postCode,
          externalProductID: selectedVariant,
        },
        {
          headers: {
          Authorization: token
          }
        }
      );
      this.ShippingMethods = methods.data;
      if (this.ShippingMethods && this.ShippingMethods.length > 0) {
      this.selectCheapestMethod(); // Ensure it's only called after data is set
      }
      }catch(e){
        console.log(e);
      }finally{      
        this.subtotal = this.baseProduct.unitPrice * this.quantity;
        this.total = (this.baseProduct.unitPrice * this.quantity) + this.shippingFee;
        this.ShippingMethodsLoading = false;
        this.priceLoading = false;
      }
      
    },
    selectMethod(index) {
      this.selectedMethod = index;
      this.shippingFee = this.ShippingMethods[index].price;
      this.total = (this.baseProduct.unitPrice * this.quantity) + this.shippingFee;
      trackEvent('CTA CLICKED', { cta_name: "Shipping method selected"});
  
    },
    selectCheapestMethod() {
      const cheapestMethodIndex = this.ShippingMethods.reduce((lowestIndex, method, index, methods) => {
        return method.price < methods[lowestIndex].price ? index : lowestIndex;
      }, 0);
      this.selectedMethod = cheapestMethodIndex;
      this.shippingFee = this.ShippingMethods[cheapestMethodIndex].price;
    },
    quantityUp(){
       
        this.quantity = this.quantity +1;
        trackEvent('CTA CLICKED', { cta_name: "Quantity up"});
    },
    quantityDown(){
        if(this.quantity>1){
            this.quantity = this.quantity -1;
            trackEvent('CTA CLICKED', { cta_name: "Quantity down"});
        }
       
    },
    handelSubmit(){
      trackEvent('CTA CLICKED', { cta_name: "PayNow"});
        this.$refs.form.validate().then(isValid => {
        if (isValid.valid) {
          // If validation passes, proceed
         // this.Checkout();
       
          this.createOrder();
         
          // Your submission logic here
        } else {
          // Handle the case where validation fails
         
     
        }
      }).catch(err => {
        console.error('Validation failed due to an error:', err);
      });

    },
    async createOrder(){
        const { selectedVariant } = this.$route.params;
        const order ={
            items:[
                {
                    userProductID: this.product._id,
                    productID:this.baseProduct._id,
                    quantity: this.quantity,
                    selectedVariant: selectedVariant,
                }
            ],
            shippingAddress:{
                companyName:'',
                firstName: this.firstname,
                lastName: this.lastname,
                country:this.country,
                city:this.city,
                state:this.state,
                addressLine1:this.AddressLineOne,
                addressLine2:this.AddressLineTwo,
                postCode:this.postCode,
                userPhoneNumber:this.phoneNumber,
            },
            shippingMethod:{
                promissedID:this.ShippingMethods[this.selectedMethod].deliveryPromiseId,
                type:this.ShippingMethods[this.selectedMethod].type,
                price:this.ShippingMethods[this.selectedMethod].price,
                name: this.ShippingMethods[this.selectedMethod].name,
            }
        }
        this.orderLoading = true; 
        const token = this.$store.getters.getToken; 
        try{
            const sessionID = await axios.post('/apis/createOrder',
                {
                order
                },
                {
                headers: {
                Authorization: token
                }
                }
            );
            if(sessionID){
            const { error } = await this.stripe.redirectToCheckout({
            sessionId: sessionID.data.id,
            });
            console.log(error)
            }

        }catch(e){
            console.log(e);
        }


    },



   },

 }
</script>
<style scoped>
.limit-width{
    max-width: 700px;
}
</style>