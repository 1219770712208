import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import NotFound404 from '../views/NotFount404.vue'
import ProductsPage from '../views/ProductsPage.vue'
import GenerateDesign from '../views/GenerateDesign.vue'
import AboutPage from '../views/AboutView.vue'
import ViewDesigns from '../views/ViewDesigns.vue'
import EditDesign from '../views/EditDesign'
import singleProduct from '../views/SingleProduct.vue'
import loginPage from '../views/LoginPage.vue'
import CreateOrder from '@/views/CreateOrder.vue'
import thankYou from '@/views/ThankYou.vue'
import store from '@/store/store'; 
import ImprintView from '@/views/ImprintView';
import PrivacyPolicy from '@/views/PrivacyPolicyView.vue'
import { trackEvent } from '@/services/amplitudeService';
const routes = [

  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutPage,

 
  },
  {
    path: '/login',
    name: 'loginPage',
    component: loginPage
 
  },
  {
    path: '/products/:ProductTag',
    name: 'ProductsPage',
    component: ProductsPage
  },
  {
    path: '/EditDesign/:UserProductID',
    name: 'EditDesignPage',
    component: EditDesign,
    meta: { requiresAuth: true }

  },
  {
    path: '/GenerateDesign/:productID',
    name: 'GenerateDesign',
    component: GenerateDesign
  },
  {
    path: '/ViewDesigns/:productID/:promptID',
    name: 'ViewDesigns',
    component: ViewDesigns,
    meta: { requiresAuth: true }
  },
  {
    path: '/SingleProduct/:userProductID',
    name: 'singleProduct',
    component: singleProduct,
    meta: { requiresAuth: true }
  },
  {
    path: '/CheckOut/:userProductID/:selectedVariant',
    name: 'checkOut',
    component: CreateOrder,
    meta: { requiresAuth: true }
  },
  {
    path: '/success/:orderID/:checkOutSessionID',
    name: 'thankYou',
    component: thankYou,
    meta: { requiresAuth: true }
  },
  {
    path: '/imprint',
    name: 'imprint',
    component: ImprintView,
   
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
   
  },

  {
    path: '/404',
    name: '404BadPage',
    component: NotFound404
  },
  {
    path: '/:catchAll(.*)',
    name: '404Page',
    component: NotFound404
  },
  {
    path: '/:catchAll(.*)*',
    name: '404Page',
    component: NotFound404
  }



]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
    scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      // If navigating back/forward, use the saved position
     // return savedPosition;
     return { top: 0 };
    } else {
      // Otherwise, scroll to the top of the page
      return { top: 0 };
    }
  },
})
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const token = store.getters.getToken;
  const tokenExpiry = store.getters.getExp;
  if (requiresAuth && (!token ||  ((Date.now()/1000) > tokenExpiry))) {
    // If route requires auth and token is missing or expired, redirect to login
 
    next({ name: 'loginPage' });
  } else {
    // Otherwise, proceed to the route
    next();
  }
});

router.afterEach((to, from) => {
  // Extract `to.params` to track current page parameters
  const toParams =
    to.params && Object.keys(to.params).length > 0
      ? Object.keys(to.params).reduce((acc, key) => {
          acc[`${key}`] = to.params[key]; // Prefix each param with "toParam_"
          return acc;
        }, {})
      : { noParams: true }; // Fallback if `to.params` is empty
console.log(toParams)
  // Track the page view event
  trackEvent('Page View', {
    pageName: to.name || 'Unknown Page',
    path: to.path,
    fromPage: from.name || 'Direct Access', // Previous page or "Direct Access"
    fromPath: from.path || null,
    ...toParams, // Include current page params
  });
});



export default router
