  <template>
    <v-container>
    <v-expansion-panels class="border-thin rounded" elevation="0">
    <v-expansion-panel v-for="(info,index) in this.AdditionalInfo" :key="index">
    <v-expansion-panel-title class="text-h7">{{ info.title }}</v-expansion-panel-title>
    <v-expansion-panel-text><div v-html="info.html"></div></v-expansion-panel-text>
  </v-expansion-panel>
</v-expansion-panels>
</v-container>

  </template>
  <script>

  export default {
    props: {
        AdditionalInfo: {
        type: Object,
        required: true,
      },
    
    },
    created(){


    },
    data() {
      return {

      
      };
    },

    methods: {

    },

  };
  
  </script>
  <style scoped> 

  </style>